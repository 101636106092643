<p-toast></p-toast>


<div class="section col-12 p-0 br-10">
    <div class="appointmentDiv">
        <h5 class="fc-dark-grey-blue fs-32 font-openSans-semibold ls-89">Start Consult</h5>
        <h6 class="fs-14 font-openSans-regular fc-twilight op-05">Please fill in the fields to continue</h6>
        <!-- <div *ngIf="role != 'patient'">
            <a (click)="ViewMdNav()" class="cp">TeleviewMD</a>
            <div class="col-sm-12 col-xs-12 col-md-5 col-lg-3 col-xl-3 p-0 mt-10">

                <label class="fs-14 fc-dark-grey-blue fs-14 fw-600 ">Select Clients</label>
                <p-dropdown [options]="clientList" styleClass="br-10" [(ngModel)]="selectedClient"
                placeholder="Client" [style]="{'width':'100%', 'height':'45px'}"
                (onChange)="getClientUrl($event)">
            </p-dropdown>
        </div>
    </div> -->

        <!-- <span (click)="navigateToclient()" *ngIf="selectedClient">{{selectedClient.name}}</span> -->
        <div class="cover-grid bc-pale-grey col-xl-12 col-md-12 col-lg-12 col-sm-12 col-xs-12 mt-20 mb-30">
            <!-- <div id="cardQuestion" class="card-question col-xl-8 col-lg-8 col-xs-12 col-md-12 col-sm-12 p-0"> -->
            <!-- <div class="col-8 pt-20 "> -->
            <div class="header fs-18 font-openSans-semibold p10 fc-dark-grey-blue">Patient Information</div>
            <div class="questionBody mb-30 p10">
                <div class="row col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 p-0 pr-15"  [ngClass]="{'clinician-border': role == 'patient'}"
                        *ngIf="role == 'patient' || role == 'admin' || role == 'healthstaff'">
                        <div class="form-group mt-15 mb-30">
                            <label class="fs-14 fc-dark-grey-blue fs-14 fw-600 ">Select Clinician</label>
                            <div>
                                <p-dropdown [options]="doctorList" styleClass="br-10" [(ngModel)]="doctorID"
                                    [style]="{'width':'100%', 'height':'45px'}" placeholder="Select Clinician"
                                    (onChange)="getDoctorName($event.value.user_id)">
                                    <ng-template let-doctor pTemplate="item">
                                        <div>{{doctor.label}}</div>
                                        <div>{{doctor.doctorSpecialty}}</div>
                                    </ng-template>
                                </p-dropdown>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-8 col-lg-8 col-md-6 col-sm-12 col-xs-12 pt-20 pr-15 d-flex align-justify" *ngIf="role == 'patient'">
                        <img *ngIf="doctorProfileImg" width="60" height="60" [src]="doctorProfileImg" class="img-circle mr-20 ml-10">
                      <div> {{doctorBio}}</div>
                    </div>
                </div>
                <div class="row col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="form-group">
                          <label class="fs-14 fc-dark-grey-blue fs-14 fw-600 ">Patient Name</label>
                          <input type="text"
                                 class="form-control mb-0 br5 fs-14 font-openSans-regular fc-dark ls-39 br-10 h-45"
                                 placeholder="Name" [(ngModel)]="patient_name">
                        </div>
              </div>
              <div class="row col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="form-group" *ngIf="doctorSpecialty != 'Behavioral'">
                           <label class="fs-14 fc-dark-grey-blue fs-14 fw-600 ">MRN</label>
                           <input type="text"
                            class="form-control btn-block mb-0 br5 fs-14 font-openSans-regular fc-dark ls-39 br-10 h-45"
                            placeholder="MRN" [(ngModel)]="patient_mrn">
                        </div>
                        <div class="form-group" *ngIf="doctorSpecialty == 'Behavioral'">
                           <label class="fs-14 fc-dark-grey-blue fs-14 fw-600 ">Account</label>
                           <input type="text"
                            class="form-control btn-block mb-0 br5 fs-14 font-openSans-regular fc-dark ls-39 br-10 h-45"
                            placeholder="AccountNum" [(ngModel)]="patient_mrn">
                        </div>
                </div>
                <div class="row col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="doctorSpecialty == 'Behavioral'">
                        <div class="form-group" *ngIf="doctorSpecialty == 'Behavioral'">
                           <label class="fs-14 fc-dark-grey-blue fs-14 fw-600 ">Referring Dr</label>
                           <input type="text"
                            class="form-control btn-block mb-0 br5 fs-14 font-openSans-regular fc-dark ls-39 br-10 h-45"
                            placeholder="ReferringDr" [(ngModel)]="referring_doctor">
                        </div>
                </div>
                <h6 class="fc-dark-grey-blue fs-14 fw-600 mt-15 font-openSans-semibold ls-39 header-top pt-20">Consultation Type</h6>
                <div class="row col-12 mt-10 mb-30 pr-0">
                    <span (click)="selectConsultation('first_time')"
                        class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 col-6 p-0 cp"><i aria-hidden="true"
                            class="mr-10"
                            [ngClass]="consultationType == 'first_time'?'fa fa-check-circle fa-lg fc-squash ':'fa fa-circle-o fa-lg text-muted'"></i>
                        <span class="fs-14 fc-dark-grey-blue fw-600 font-openSans-semibold">First visit</span></span>
                    <span (click)="selectConsultation('follow_up')"
                        class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 col-6 p-0 cp"><i aria-hidden="true"
                            class="mr-10"
                            [ngClass]="consultationType == 'follow_up'?'fa fa-check-circle fa-lg fc-squash':'fa fa-circle-o fa-lg text-muted'"></i>
                        <span class="fs-14 fc-dark-grey-blue fw-600 font-openSans-semibold">Follow Up</span></span>
                </div>
                <div class="row">
                    <div class="mb-30" [ngClass]="role == 'patient'? 'col-sm-12 col-xs-12 col-md-6 col-lg-6 col-xl-6' : 'col-12'">
                        <p class="fc-dark-grey-blue fs-14 fw-600 font-openSans-regular">Please specify your chief
                            medical
                            complaint</p>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0">
                            <textarea class="" class="form-control" rows="5"
                                [(ngModel)]="patient_complaint"></textarea>
                        </div>
                    </div>

                    <div class="col-sm-12 col-xs-12 col-md-6 col-lg-4 col-xl-4 d-flex" *ngIf=" role == 'patient'" >
                        <div class="image-upload fr attachment-imgs col-12 no-padding">

                            <div class="mb-15 fs-14 font-openSans-regular" style="color: #090909">{{ss.files.length}} files Added</div>
                            <p-card>
                                <div *ngFor="let file of ss.files" class="font-openSans-regular xs-font">
                                    <div>
                                        {{file.name}}
                                        <span (click)="onRemove(file)"><i class="fa fa-times close-icon cp" aria-hidden="true"></i></span>
                                    </div>
                                </div>
                            </p-card>
                            <input id="file-input" type="file" name="files" accept="image/*" multiple (change)="handleFileInput($event)" />
                            <label for="file-input" class="mb-0 cp attach-label font-openSans-regular xs-font fc-button-color mt-05">
                                <i class="fa fa-plus fs-11 pr-05" aria-hidden="true"></i>Add Attachment
                            </label>
                        </div>
                    </div>

                </div>

                <div class="row col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 mt-10 p-0">
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12 mb-10 patientData">
                        <label class="fc-dark-grey-blue fs-14 fw-600 font-openSans-regular">Allergies</label>
                        <textarea class="" class="form-control" rows="4" [(ngModel)]="patient_allergies"></textarea>
                    </div>

                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12 mb-10 patientData">
                        <label class="fc-dark-grey-blue fs-14 fw-600 font-openSans-regular">Medications</label>
                        <textarea class="" class="form-control" rows="4" [(ngModel)]="patient_medication"></textarea>

                    </div>

                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12 mb-10 patientData">
                        <label class="fc-dark-grey-blue fs-14 fw-600 font-openSans-regular">Pre-existing
                            conditions</label>
                        <textarea class="" class="form-control" rows="4" [(ngModel)]="patient_preexisting_conditions"></textarea>
                    </div>
                </div>

            </div>
            <!-- </div> -->
        </div>
        <div class="d-flex justify-end mb-20">
            <button type="button"
                class="h-45 button-width input-btn btn br-10 bc-white border-cloudy-blue fs-14 pull-right mr-10 font-openSans-semibold fc-cloudy-blue"
                (click)="cancel()">Cancel</button>
            <button type="button"
                class="h-45 input-btn btn fw-600 button-width bc-soft-blue fs-14 font-openSans-semibold fc-white  br-10"
                (click)="submit()">Submit</button>
        </div>

    </div>
</div>
