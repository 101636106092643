<p-toast></p-toast>
<app-loader [loading]="loading"></app-loader>

<div class="section col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12">
  <div class="appointmentDiv br-10">
    <!-- <ng-container *ngIf="type != 'ad'">

            <h5 class="fc-dark-grey-blue fs-32 font-openSans-semibold">Appointments</h5>
            <p class="fs-14 fc-twilight font-openSans-regular op-05">View booked and upcoming appointments based on the date
                range selected</p>
            </ng-container> -->

    <div class="">
      <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-xs-12 p-0">
        <span class="d-block fc-dark-grey-blue fs-32 font-openSans-semibold"
          >Appointments</span
        >
      </div>
      <div class="row mb-10" style="align-items: center">
        <div class="col-lg-7 col-xl-7 col-md-6 col-sm-12 col-xs-12">
          <label class="fs-14 fc-twilight font-openSans-regular op-05"
            >View all appointments based on the selected date range and
            Clinician</label
          >
        </div>
        <div class="col-lg-5 col-xl-5 col-md-6 col-sm-8 col-xs-8">
          <div class="col-lg-10 col-xl-10 col-md-12 col-sm-12 col-sm-12 fr p-0">
            <span class="search-icon cursor-pointer" data-cy="clear_search">
              <img src="assets/images/gg-search.svg" alt="" />
            </span>
            <input
              type="text"
              class="br-10 form-control h-45 fc-twilight font-openSans-regular pr-50"
              [(ngModel)]="searchVal"
              placeholder="{{ placeholder }}"
              name="searchPatient"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      class="cover-grid bc-pale-grey-three col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 p-0 admin-dropdown"
    >
      <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 p-0">
        <div class="input-group">
          <div class="input-group-append inputIcon">
            <!-- <i class="fa fa-calendar icon"></i> -->
            <img src="assets/images/calendar-icon.svg" />
          </div>
          <input
            type="text"
            class="inputDate outline-none cp pr-20"
            readonly
            ngxDaterangepickerMd
            [(ngModel)]="selected"
            [showCustomRangeLabel]="true"
            [alwaysShowCalendars]="true"
            [ranges]="ranges"
            (change)="changed($event)"
            [linkedCalendars]="true"
            [isInvalidDate]="isInvalidDate"
            [showCancel]="true"
            opens="true"
            drops="down"
            [locale]="{ format: 'MMM Do' }"
            placeholder="Select please..."
            [lockStartDate]="false"
          />
          <div class="input-group-append drop-icon">
            <img src="assets/images/path-down.svg" class="t-02" />
          </div>
          <p-dropdown
            [style]="{ 'border-color': 'transparent' }"
            styleClass="dropdown-no-border"
            *ngIf="role == 'admin'"
            [options]="doctorList"
            [(ngModel)]="sharedService.selectDoctor"
            (onChange)="getSelectedDoctor($event.value)"
          ></p-dropdown>
        </div>

        <div *ngIf="!appointmentList.length" class="center pt-20 pb-20">
          There are no appointments booked in the date range selected
        </div>
        <div *ngIf="appointmentList.length" class="appointmentList mb-30">
          <p-table
            #dt2
            [value]="appData | search: searchField:searchVal"
            [style]="{ 'font-size': '13px' }"
            styleClass="appointmentFeature"
            [globalFilterFields]="['patient_name']"
          >
            <ng-template pTemplate="header">
              <tr>
                <!-- <th>No.</th> -->
                <th class="xs-font font-openSans-regular fc-slate-grey">
                  Patient
                </th>
                <th class="xs-font font-openSans-regular fc-slate-grey">
                  Doctor
                </th>
                <th class="xs-font font-openSans-regular fc-slate-grey">
                  Status
                </th>
                <th class="xs-font font-openSans-regular fc-slate-grey">
                  Date and Time
                </th>
                <!-- <th class="xs-font font-openSans-regular fc-slate-grey">Time</th> -->
                <th class="xs-font font-openSans-regular fc-slate-grey">
                  Visit Type
                </th>
                <th class="xs-font font-openSans-regular fc-slate-grey">
                  Actions
                </th>
                <!-- <th></th> -->
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-list>
              <tr *ngIf="list == -1">
                <td
                  class="sm-font font-openSans-regular fc-dark text-center"
                  colspan="6"
                >
                  Appointment details not found for the entered patient
                </td>
              </tr>
              <tr *ngIf="list != -1">
                <!-- <td>{{rowIndex+1}}</td> -->
                <td
                  class="sm-font font-openSans-semibold fc-dark"
                >
                  {{ list.patient_name | titlecase }}
                </td>
                <td
                  class="sm-font font-openSans-semibold fc-dark"
                >
                  {{ list.doctor_name | titlecase }}
                </td>
                <td class="sm-font font-openSans-semibold fc-dark">
                  {{ list.status | titlecase }}
                </td>

                <td class="sm-font font-openSans-regular fc-dark">
                  {{ list.date_time }}
                </td>
                <!-- <td class="sm-font font-openSans-regular fc-dark text-noWrap">{{list.time}}</td> -->
                <td class="sm-font font-openSans-regular fc-dark text-noWrap">
                  {{ list.visit_type }}
                </td>
                <td
                  class="sm-font font-openSans-regular fc-soft-blue text-noWrap"
                >
                  <!-- <span *ngIf="list.appointment_status == 'completed' || list.appointment_status == 'cancelled'">&nbsp;</span> -->
                  <button
                    type="button"
                    style="vertical-align: unset"
                    class="btn btn-link mr5 p-0 fc-soft-blue b-05"
                    [disabled]="
                      list.appointment_status == 'completed' ||
                      list.appointment_status == 'cancelled' ||
                      list.appointment_status == 'rescheduled'
                    "
                    (click)="join(list, list.appointment_id)"
                  >
                    Join
                  </button>
                  <!-- </td>
                                <td class="fc-black xs-font font-openSans-regular text-noWrap"> -->
                  <a
                    style="color: #333333; text-decoration: none"
                    class="mr5 fc-soft-blue b-05"
                    *ngIf="type == 'patient'"
                    [routerLink]="['/patient/appointment']"
                    (click)="setAppointmentId(list.appointment_id)"
                    [queryParams]="{ page: 'appointment-details' }"
                    >View
                  </a>
                  <a
                    style="color: #333333; text-decoration: none"
                    class="mr5 fc-soft-blue b-05"
                    *ngIf="type !== 'patient'"
                    (click)="setAppointmentId(list.appointment_id)"
                    routerLink="{{ getUrl() }}"
                    [queryParams]="{ page: 'details' }"
                  >
                    View
                  </a>

                  <!-- <span type="button" #buttonMenu pButton class="p-button-info p-button-text p-0" icon="pi pi-ellipsis-v"
                                        (click)="menu.toggle($event)"></span>
                                    <p-menu #menu [popup]="true"  styleClass="listMenu" [model]="getMenuItemsForItem(list)" class="mr5" appendTo="body"
                                        [style]="{'left':'613px','color':'#576af8'}" (click)="menu.hide()"></p-menu>
                                    <div #body></div> -->
                  <button
                    mat-icon-button
                    [matMenuTriggerFor]="menu"
                    class="material-button"
                  >
                    <mat-icon>more_vert</mat-icon>
                  </button>

                  <mat-menu #menu="matMenu">
                    <!-- give local reference and tell this is manu by assigning 'matMenu' -->
                    <button
                      *ngIf="!isRimidyUser"
                      (click)="confirmReschedule(list)"
                      mat-menu-item
                      [disabled]="
                        list.appointment_status == 'completed' ||
                        list.appointment_status == 'cancelled' ||
                        list.appointment_status == 'rescheduled'
                      "
                    >
                      <span>Reschedule </span>
                    </button>
                    <button
                      (click)="confirmCancel(list)"
                      mat-menu-item
                      [disabled]="
                        list.appointment_status == 'completed' ||
                        list.appointment_status == 'cancelled' ||
                        list.appointment_status == 'rescheduled'
                      "
                    >
                      <span>Cancel</span>
                    </button>
                  </mat-menu>
                </td>
              </tr>
            </ng-template>
          </p-table>

          <div
            *ngIf="appointmentList.length > 0"
            class="pull-right p20"
            style="position: absolute; right: 0"
          >
            <span class="xs-font font-openSans-regular fc-black pr-10"
              >{{ appointmentList.length }} Record(s)</span
            >
            <span
              *ngIf="enableLeft"
              class="cp xs-font font-openSans-regular fc-soft-blue"
              (click)="pagination('p')"
            >
              <i class="fa fa-chevron-left" aria-hidden="true"></i>
              Last 5
            </span>
            <span
              *ngIf="!enableLeft"
              class="xs-font font-openSans-regular fc-soft-blue op-05"
            >
              <i class="fa fa-chevron-left op-05" aria-hidden="true"></i>
              Last 5
            </span>
            <span class="pr-10 pl-10 fc-soft-blue">|</span>
            <span
              *ngIf="enableRight"
              class="cp xs-font font-openSans-regular fc-soft-blue"
              (click)="pagination('n')"
            >
              Next 5
              <i class="fa fa-chevron-right" aria-hidden="true"></i>
            </span>
            <span
              *ngIf="!enableRight"
              class="xs-font font-openSans-regular fc-soft-blue op-05"
            >
              Next 5
              <i class="fa fa-chevron-right op-05" aria-hidden="true"></i>
            </span>
          </div>
        </div>

        <div *ngIf="!isRimidyUser" class="text-center mb-20 pt-30">
          <a
            class="btn btn-primary fs-14 font-openSans-semibold fc-white bc-soft-blue br-10 lh-025"
            routerLink="{{ appointmentUrl() }}"
            [queryParams]="{ page: 'questionaire' }"
            >Book an Appointment</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
