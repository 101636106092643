
<app-loader [loading]="loading"></app-loader>
<p-toast></p-toast>
<!-- <p-dialog *ngIf="showPayment" header="Make Payment" [closable]="false" [(visible)]="showPayment" [closeOnEscape]= "false" [style]="{width: '40vw'}" [modal]="true" (onHide)="navigateBack()"> -->
<!-- </p-dialog> -->

<div class="section" *ngIf="!showPayment">
    <div class="hornDiv br-10">
        <h5 class="font-openSans-semibold fs-32 fc-dark-grey-blue ls-89  ml-40 pt-20">Ongoing Consultation</h5>
        <h6 class="fs-14 font-openSans-regular fc-twilight ls-03 ml-40">Please use the screen controls to
            mute/unmute, switch video on/off and end
            call</h6>
             <!-- <div class="text-center fc-dark-grey-blue fs-14 font-openSans-semibold" *ngIf="url_user_id == loged_user_id && start_time && !sharedService.joinCall(start_time, end_time)">Please join the call only respective time </div>
        <div class="text-center fc-dark-grey-blue fs-14 font-openSans-semibold" *ngIf="url_user_id != loged_user_id">You dont have access to join the call</div> -->
        <div class="videoLayout">
            <div id="horn-conference">
            </div>
            <div id="horn-container">
            </div>
        </div>
    </div>
</div>
