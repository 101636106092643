import { Injectable } from '@angular/core';
import { Router, CanActivate, CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}
@Injectable({
  providedIn: 'root'
})

export class AuthGuardService implements CanDeactivate<CanComponentDeactivate>{

  constructor(public auth: AuthService, public router: Router) { }

  canActivate(): boolean {
    if (!this.auth.isAuthenticated()) {
      // const pathName = window.location.pathname;
      // const href = window.location.href;
      // if (pathName == '/admin/appointment' || pathName == '/doctor/appointment' || pathName == '/patient/appointment') {
      //   let endUrl = href.split('?');
      //   this.router.navigateByUrl('/appointment?' + endUrl[1]);
      // } else {
        this.router.navigate(['login']);
      // }
      return false;
    }
    return true;
  }

  canDeactivate(component: CanComponentDeactivate,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {

    let url: string = state.url;
    // console.log('Url: ' + url, location.pathname);
    if (this.router.url.includes('video')) {
      return confirm('Are you sure you want to leave this page?');
      // window.location.reload();

    } else {
      return true;
    }
  }
}
