import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../security/auth.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SharedService } from '../shared.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit {
  hasToken: boolean;
  subscription$: Subject<boolean> = new Subject<boolean>();
  // user_name: any = '';
  // imageURL: any = '';
  language = [
    { label: 'English', value: 'en' },
    { label: 'Spanish', value: 'sp' },
  ];
  defaultLang = 'en';
  
  constructor(public authService: AuthService, public ss: SharedService, 
    public router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.hasToken = localStorage.getItem('token') ? true : false;
    this.getUserName();
  }

  navigateHome() {
    if(this.authService.isAuthenticated()) {
      const role = localStorage.getItem('role');
      this.router.navigate([`/${role}/dashboard`]);
    }
  }

  getUserName() {
    this.authService.getUserDetails().pipe(takeUntil(this.subscription$)).subscribe(
      res => {        
        if(res['response']['data']['user_role'] == 'doctor'){
          this.ss.user_name = "Dr" + " " + res['response']['data']['first_name'] + " " +  res['response']['data']['last_name'];
        }else{
          this.ss.user_name = res['response']['data']['first_name'] + " " +  res['response']['data']['last_name'];
        }
        this.ss.first_name =  res['response']['data']['first_name'];
        this.ss.last_name = res['response']['data']['last_name'];
        this.ss.imageURL = res['response']['data']['image_url'];        
      },
      err => {
      }
    );
  }

}
