<div class="section">
    <div class="appointmentDiv br-10">
        <h5 class="fc-dark-grey-blue fs-32 font-openSans-semibold ls-89">Appointment Cancellation</h5>

        <div class="cover-grid  col-xl-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 mt-20">

            <div class="text-center mb-30 mt-30" *ngIf="data">
                <img src="assets/images/confirm-calendar.svg">
                <h6 class="fc-squash fs-14 mt-30 font-openSans-semibold"><b>Appointment Cancelled</b></h6>
                <p class="fs-14 fc-dark text-center mb-30 ls-39" *ngIf="type == 'patient'">Your appointment with &nbsp;<b>{{data?.doctor_name | titlecase}}</b> has been cancelled</p>
                <p class="fs-14 fc-dark text-center mb-30 ls-39" *ngIf="type == 'doctor'">Your appointment with&nbsp;<b>{{data?.patient_name | titlecase}}</b> has been cancelled </p>
            </div>

            <div class="innerInfo col-xl-6 col-lg-6 col-md-8 col-xs-10 col-sm-10" *ngIf="data">
                <div class="fw-600 tele-details">
                    <h6 class="fs-14 fc-dark-grey-blue mb-20 font-openSans-semibold ls-39">TeleConsultation</h6>
                    <p class="xs-font fc-twilight font-openSans-regular d-flex"><i class="fa fa-calendar-o mr-10 t-03" aria-hidden="true"></i>
                    {{data?.time}}, {{data?.date | date: 'EEEE, MMMM d, y'}}
                    </p>
                   
                    
                </div>
            </div>
            <div class="text-center mt-30 mb-10">
                <button type="button" class="btn bc-soft-blue fc-white next-btn fw-600" (click)="reset()" >Continue</button>
            </div>
        </div>

    </div>
</div>
