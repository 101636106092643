<div class="modal-header border-none">
    <h4 class="modal-title fc-button-color font-openSans-semibold fs-26">{{ title }}</h4>
    <!-- <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button> -->
</div>
<div class="modal-body font-openSans-regular">
    <p class="fc-slate-grey fs-16">{{ message }}</p>

    <span class="fs-14 fw-600 fc-dark">{{data?.doctor_name ? data.doctor_name : data.patient_name | titlecase}}</span>
    <p class="fc-grey fs-14">{{data?.date}} {{data?.time}}</p>
</div>
<div class="modal-footer border-none">
    <button type="button"
        class="input-btn br-10 button-width btn bc-white border-cloudy-blue fs-14 mr-10 font-openSans-semibold fc-cloudy-blue"
        (click)="decline()">{{ btnCancelText }}</button>
    <button type="button"
        class=" input-btn btn fw-600 button-width bc-soft-blue fs-14 font-openSans-semibold fc-white  br-10"
        (click)="accept()">{{ btnOkText }}</button>
</div>