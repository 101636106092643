import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(items: any[], field : string, value): any[] {
    if (!items) return [];
    if (!value || value.length === 0) return items;
    const list =  items.filter(it =>
    it[field].toLowerCase().includes(value.toLowerCase()));
    return list.length ? list : [-1];
  }

}
