import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Validators } from '@angular/forms';
import { SharedService } from '../shared.service';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';


@Component({
  selector: 'appointment-slot',
  templateUrl: './appointment-slot.component.html',
  styleUrls: ['./appointment-slot.component.sass'],
  providers: [DatePipe, SharedService, MessageService]
})
export class AppointmentSlotComponent implements OnInit {
  detailsForm = new FormGroup({
    name: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    message: new FormControl(''),
  });
  date: Date;
  isShow: boolean = false;
  @Output() appointmentSlot: EventEmitter<any> = new EventEmitter;
  startTime: any;
  endTime: any;
  patientName: '';
  patientMRN: '';
  @Input() userName: string = '';
  @Output() page: EventEmitter<any> = new EventEmitter;
  minimumDate = new Date();
  timeOption: any = [];


  // timeOption = [
  //   { label: '12:00 AM', value: '00:00' }, { label: '12:15 AM', value: '00:15' }, { label: '12:30 AM', value: '00:30' }, { label: '12:45 AM', value: '00:45' },
  //   { label: '1:00 AM', value: '01:00' }, { label: '1:15 AM', value: '01:15' }, { label: '1:30 AM', value: '01:30' }, { label: '1:45 AM', value: '01:45' },
  //   { label: '2:00 AM', value: '02:00' }, { label: '2:15 AM', value: '02:15' }, { label: '2:30 AM', value: '02:30' }, { label: '2:45 AM', value: '02:45' },
  //   { label: '3:00 AM', value: '03:00' }, { label: '3:15 AM', value: '03:15' }, { label: '3:30 AM', value: '03:30' }, { label: '3:45 AM', value: '03:45' },
  //   { label: '4:00 AM', value: '04:00' }, { label: '4:15 AM', value: '04:15' }, { label: '4:30 AM', value: '04:30' }, { label: '4:45 AM', value: '04:45' },
  //   { label: '5:00 AM', value: '05:00' }, { label: '5:15 AM', value: '05:15' }, { label: '5:30 AM', value: '05:30' }, { label: '5:45 AM', value: '05:45' },
  //   { label: '6:00 AM', value: '06:00' }, { label: '6:15 AM', value: '06:15' }, { label: '6:30 AM', value: '06:30' }, { label: '6:45 AM', value: '06:45' },
  //   { label: '7:00 AM', value: '07:00' }, { label: '7:15 AM', value: '07:15' }, { label: '7:30 AM', value: '07:30' }, { label: '7:45 AM', value: '07:45' },
  //   { label: '8:00 AM', value: '08:00' }, { label: '8:15 AM', value: '08:15' }, { label: '8:30 AM', value: '08:30' }, { label: '8:45 AM', value: '08:45' },
  //   { label: '9:00 AM', value: '09:00' }, { label: '9:15 AM', value: '09:15' }, { label: '9:30 AM', value: '09:30' }, { label: '9:45 AM', value: '09:45' },
  //   { label: '10:00 AM', value: '10:00' }, { label: '10:15 AM', value: '10:15' }, { label: '10:30 AM', value: '10:30' }, { label: '10:45 AM', value: '10:45' },
  //   { label: '11:00 AM', value: '11:00' }, { label: '11:15 AM', value: '11:15' }, { label: '11:30 AM', value: '11:30' }, { label: '11:45 AM', value: '11:45' },
  //   { label: '12:00 PM', value: '12:00' }, { label: '12:15 PM', value: '12:15' }, { label: '12:30PM', value: '12:30' }, { label: '12:45 PM', value: '12:45' },
  //   { label: '1:00 PM', value: '13:00' }, { label: '1:15 PM', value: '13:15' }, { label: '1:30PM', value: '13:30' }, { label: '1:45 PM', value: '13:45' },
  //   { label: '2:00 PM', value: '14:00' }, { label: '2:15 PM', value: '14:15' }, { label: '2:30PM', value: '14:30' }, { label: '2:45 PM', value: '14:45' },
  //   { label: '3:00 PM', value: '15:00' }, { label: '3:15 PM', value: '15:15' }, { label: '3:30PM', value: '15:30' }, { label: '3:45 PM', value: '15:45' },
  //   { label: '4:00 PM', value: '16:00' }, { label: '4:15 PM', value: '16:15' }, { label: '4:30PM', value: '16:30' }, { label: '4:45 PM', value: '16:45' },
  //   { label: '5:00 PM', value: '17:00' }, { label: '5:15 PM', value: '17:15' }, { label: '5:30PM', value: '17:30' }, { label: '5:45 PM', value: '17:45' },
  //   { label: '6:00 PM', value: '18:00' }, { label: '6:15 PM', value: '18:15' }, { label: '6:30PM', value: '18:30' }, { label: '6:45 PM', value: '18:45' },
  //   { label: '7:00 PM', value: '19:00' }, { label: '7:15 PM', value: '19:15' }, { label: '7:30PM', value: '19:30' }, { label: '7:45 PM', value: '19:45' },
  //   { label: '8:00 PM', value: '20:00' }, { label: '8:15 PM', value: '20:15' }, { label: '8:30PM', value: '20:30' }, { label: '8:45 PM', value: '20:45' },
  //   { label: '9:00 PM', value: '21:00' }, { label: '9:15 PM', value: '21:15' }, { label: '9:30PM', value: '21:30' }, { label: '9:45 PM', value: '21:45' },
  //   { label: '10:00 PM', value: '22:00' }, { label: '10:15 PM', value: '22:15' }, { label: '10:30PM', value: '22:30' }, { label: '10:45 PM', value: '22:45' },
  //   { label: '11:00 PM', value: '23:00' }, { label: '11:15 PM', value: '23:15' }, { label: '11:30PM', value: '23:30' }, { label: '11:45 PM', value: '23:45' }
  // ];
  selectedTime: any;
  confirmTime: any;
  selectedDate = new Date();
  newDate = new Date();
  submitted = false;
  loading: boolean;
  arr: any[] = [];
  dateAfterThreeMonths: any;
  validDates: Array<Date> = [];
  invalidDates = [];
  maxDate = new Date();
  date1: number;
  meridian = true;
  hasSlots: boolean = false;
  booking_startTime = { hour: 12, minute: 0 };
  booking_endTime = { hour: 13, minute: 0 }
  availability_id: number;
  reschedule: boolean = false;
  reschedule_id: any;
  doctorid: any;

  // name: any = '' ;
  // email: any = '';
  constructor(private router: Router, private datePipe: DatePipe,
    private messageService:MessageService, public sharedService: SharedService,
    private active: ActivatedRoute,) {
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.active.queryParams.subscribe((data) => {
      if (data.reschedule) {
        this.reschedule = true;
        this.reschedule_id = data.reschedule;
      }
      if(data.doctorID){
        this.doctorid = data.doctorID;        
      }
    });  
    const date = this.maxDate;
    this.maxDate.setMonth(date.getMonth() + 3);
    this.dateAfterThreeMonths = this.newDate.setMonth(this.newDate.getMonth() + 3);
    this.getDoctorAppointment(this.selectedDate);
    // this.getDoctorAvailability();
    setTimeout(() => {
      this.getAvailableTimeSlots(new Date());
    },1000);   
    
  }

  get f() { return this.detailsForm.controls; }


  selectTime(time) {  
    // if((!this.userName || this.userName == '') && !this.reschedule ) {
    //  this.messageService.clear();
    //  this.messageService.add({ severity: 'warn', summary: 'Error', detail: `Please fill all the form details ` });
    //  return;
    // }
    this.selectedTime = time;
    const local = this.selectedDate.toLocaleDateString();
    const fDate = this.sharedService.convertToUnixTimeStamp(this.selectedDate, this.selectedTime.value);
    const reverse = this.sharedService.convertToStandardTime(fDate);
  }

  confirm(time) { 
    window.scroll(0, 0)
    this.startTime = time.starttime;
    this.endTime = time.endtime;
    this.availability_id = time.availability_id;
    this.confirmTime = `${time.starttime} - ${time.endtime}`;
    // this.startTime = time.label;
    let todayTime = new Date();
    const [hr, min] = time.starttime.split(":")
    todayTime.setHours(hr, min, 0);
    // add 15 for the selected time 
    // todayTime.setMinutes(todayTime.getMinutes() + 15);
    // this.endTime = moment(todayTime).format('LT');
    this.confirmTime = `${this.startTime} - ${this.endTime}`;
    this.next();
  }

  next() {
    this.submitted = true;
    // if (this.detailsForm.invalid) {
    //   return;
    // }
    let obj = {
      selectedDate: this.selectedDate,
      selectedTime: this.selectedTime,
      // name: this.f.name.value,
      // email: this.f.email.value,
      page: this.reschedule ? 'reschedule' : 'confirmation',
      startTime: this.startTime,
      endTime: this.endTime,
      date: this.date1,
      availability_id: this.availability_id,
      appointment_id: this.reschedule_id
    }    
    this.appointmentSlot.emit(obj);
  }

  confirmBooking() {
    const obj = {
      start: this.booking_startTime.hour + ':' + this.booking_startTime.minute,
      end: this.booking_endTime.hour + ':' + this.booking_endTime.minute
    }
    if (this.validateDate(obj)) {
      this.startTime = this.convertTime(this.booking_startTime);
      this.endTime = this.convertTime(this.booking_endTime);
      this.confirmTime = `${this.startTime} - ${this.endTime}`;
      window.scroll(0, 0);
      this.next();
    }
  }

  validateDate(obj): boolean {
    var timefrom = new Date();
    let temp = obj.start.split(":");
    timefrom.setHours((parseInt(temp[0]) - 1 + 24) % 24);
    timefrom.setMinutes(parseInt(temp[1]));

    var timeto = new Date();
    let temp1 = obj.end.split(":");
    timeto.setHours((parseInt(temp1[0]) - 1 + 24) % 24);
    timeto.setMinutes(parseInt(temp1[1]));
    // start time is greater then end time
    if (timeto < timefrom) {
      alert('Start time should be smaller than end time!');
      return false
    }
    // check if time selected is less then 30 mins
    if (Math.abs(timeto.getHours() - timefrom.getHours()) == 0 && Math.abs(timeto.getMinutes() - timefrom.getMinutes()) < 30) {
      alert('Time duration should be minimum of 30 minutes!');
      return false
    }
    return true
  }

  convertTime(value) {
    const time = value.hour + ':' + value.minute;
    return moment(time, ["HH.mm"]).format("hh:mm A");
  }

  getDoctorAvailability() {
    const start_date = this.sharedService.convertToUnixTimeStamp(this.selectedDate, '00:00');
    const end_date = this.sharedService.convertToUnixTimeStamp(this.dateAfterThreeMonths, '23:59');
    const role = localStorage.getItem('role');
    let doctor_id;
    if (role == 'patient' || role == 'admin' || role == 'healthstaff') {
      const data = JSON.parse(localStorage.getItem('userDetails'));
      doctor_id = data.doctorID;
    } else {
      doctor_id = localStorage.getItem('user_id');
    }
    this.sharedService.getAvailability(doctor_id, start_date, end_date).subscribe((res) => {
      this.loading = false;
      if (res['success'] == true) {
        if (!res['data'].length) {
          return;
        }
        // let dateval =[];
        res['data'].forEach(ele => {
          let date = moment(ele.date).toDate();
          this.validDates.push(date);
          // dateval.push(moment(ele.date).format('L'))
        });
        const min = this.validDates.reduce(function (a, b) { return a < b ? a : b; });
        const max = this.validDates.reduce(function (a, b) { return a > b ? a : b; });
        this.minimumDate = new Date(min); // min date to set when doctor available
        this.maxDate = new Date(max); // max date to set still when doctor available
        let startDate = moment(min);
        let endDate = moment(max);
        let date = [];
        for (var m = moment(startDate); m.isBefore(endDate); m.add(1, 'days')) {
          date.push(m.format('L'));
        } // get all dates between startDate and endDate
        let formatdates = [...this.validDates];
        const enableDates = formatdates.map(ele => moment(ele).format('L')); //convert date to '01/08/2021' format
        const uniqDates = [...new Set(enableDates)]; //remove duplicates 
        this.invalidDates = date.filter(d => !uniqDates.includes(d)).map(d => moment(d).toDate()); //get invalid dates from the valid dates array
      } else {

      }
    }, (err) => {
      this.loading = false;
    });
  }

  getAvailableTimeSlots(value) {    
    this.date1 = this.sharedService.convertToUnixTimeStamp(value, '00:00')
    this.timeOption = [];
    const start_date = this.sharedService.convertToUnixTimeStamp(value, '00:00');
    const end_date = this.sharedService.convertToUnixTimeStamp(value, '23:59');
    const role = localStorage.getItem('role');
    let doctor_id;
    if(!this.reschedule){
      if (role == 'patient' || role == 'admin' || role == 'healthstaff') {
        const data = JSON.parse(localStorage.getItem('userDetails'));
        doctor_id = data.doctorID;
      } else {
        doctor_id = localStorage.getItem('user_id');      
      } 
    }else if(this.reschedule){
      doctor_id = this.doctorid;;
    }
    this.sharedService.getDoctorAvailability(doctor_id, start_date, end_date).subscribe((res) => {
      this.loading = false;
      if(res['data'].length == 0){
        this.hasSlots = false;
      }else {   
        this.timeOption = [];
        res['data'].forEach(ele => {
          let obj = {};
          obj['starttime'] = moment(ele['start_time']).format('LT');
          obj['endtime'] = moment(ele['end_time']).format('LT');
          obj['availability_id'] = ele['availability_id'];
          this.timeOption.push(obj);          
          this.hasSlots = true;                    
          // this.recursiveTimeSlots(ele['start_time'], ele['buffer_time'], ele['call_duration'], ele['end_time']);
        });
    }
    }, (err) => {
      this.loading = false;
      this.hasSlots = false;
    });

  }

  recursiveTimeSlots(start_time, buffer_time, call_duration, end_time) {
    let obj = {};
    obj['starttime'] = moment(start_time).format('LT');
    let dt = new Date(start_time);
    let min = dt.getMinutes() + buffer_time + call_duration;
    let dt2 = new Date(dt.setMinutes(min));
    let min11 = new Date(dt.setMinutes(call_duration));
    let c1 = new Date(start_time);
    let c2 = new Date();
    if (c1 <= c2) {
      obj['disabled'] = true;
    } else if (c1 > c2) {
      obj['disabled'] = false;
    }
    obj['endtime'] = moment(min11).format('LT');
    // this.timeOption.push(obj);    
    ///check for time slot less than end time with buffer and call duration
    let dt3 = new Date(end_time);
    let min1 = dt3.getMinutes() - buffer_time - call_duration;
    let dt4 = new Date(dt3.setMinutes(min1));
    //ends here       
    if (dt2 <= dt4) {
      this.recursiveTimeSlots(dt2, buffer_time, call_duration, end_time);
    }
  }

  getDoctorAppointment(value) {
    // if(this.role ==)
    this.loading = true;

    const start_date = this.sharedService.convertToUnixTimeStamp(this.selectedDate, '00:00');
    const end_date = this.sharedService.convertToUnixTimeStamp(this.selectedDate, '23:59');
    // const date = this.selectedDate.valueOf()
    const role = localStorage.getItem('role');
    let doctor_id;
    if (role == 'patient' || role == 'admin' || role == 'healthstaff') {
      const data = JSON.parse(localStorage.getItem('userDetails'));
      doctor_id = data.doctorID;
    } else {
      doctor_id = localStorage.getItem('user_id');
    }

    this.sharedService.getListOfAppointments(doctor_id, start_date, end_date).subscribe((res) => {
      this.loading = false;
      if (res['success'] === true) {
        this.arr = [];
        res['data'].forEach(element => {
          const dt = new Date(element.start_time);
          const hours = dt.getHours(); // gives the value in 24 hours format
          const minutes = dt.getMinutes() === 0 ? '00' : dt.getMinutes();
          const value = dt.toLocaleTimeString()
          const finalTime = hours + ":" + minutes;
          this.arr.push(finalTime);
        });
        // this.timeOption = this.timeOption.filter(time => !this.arr.includes(time.value));
      } else {

      }
    }, (err) => {
      this.loading = false;
    });
  }

  slotAvaliable(time) {
    const index = this.arr.indexOf(time.value);
    // console.log(index)
    return index > -1? true : false;
  }

  checkPastTime(time) {
    let timeSlot = this.selectedDate;
    const [hr, min] = time.starttime.split(":")
    timeSlot.setHours(hr, min, 0);
  
    const currTime = new Date();
    return timeSlot.getTime() < currTime.getTime() ? true : false; 
  }

  navigateToQuestionaire() {
    let obj = {
      page: 'questionaire'
    }
    this.page.emit(obj);
  }

}
