import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import jwt_decode from 'jwt-decode';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, EMPTY, of, NEVER, Subject } from 'rxjs';
import {
  map,
  catchError,
  tap,
  distinctUntilChanged,
  switchMap,
  filter,
  delay,
  debounceTime,
} from 'rxjs/operators';
import { EnvironmentService } from '../services/environment.service';
import { MessageService } from 'primeng/api';
import { LoaderComponent } from '../shared/loader/loader.component';
import { SharedService } from '../shared/shared.service';
interface Credentials {
  email: string;
  password: string;
}

export interface User {
  id: string;
  name: string;
  mobile: string;
  email: string;
  imageUrl: string;
  user_status: string;
}

export interface AuthUser extends User {
  token: string;
  expiration: number;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private redirectOnSignIn: string = null;
  public get redirectUrl() {
    if (!this.redirectOnSignIn) {
      try {
        this.redirectOnSignIn = localStorage.getItem('redirectOnSignIn');
      } catch (err) {
        localStorage.removeItem('redirectOnSignIn');
        console.log('Cleared key redirectOnSignIn invalid format');
      }
    }

    return this.redirectOnSignIn;
  }
  public set redirectUrl(url) {
    if (url) {
      this.redirectOnSignIn = url;
      localStorage.setItem('redirectOnSignIn', this.redirectOnSignIn);
    }
  }

  constructor(
    public jwtHelper: JwtHelperService,
    private env: EnvironmentService,
    private http: HttpClient,
    private router: Router,
    private ss: SharedService // private messageService: MessageService,
  ) {}
  static getUser(authorization: any): AuthUser {
    let expiration: number;
    try {
      let exp: number;
      const decoded = jwt_decode(authorization.token);
      exp = decoded['exp'];
      expiration = exp * 1000;
    } catch (e) {
      console.log(e);
      console.error(`${e.name}: ${e.message}`);
      expiration = new Date().getTime(); // now
    }
    const {
      id,
      name,
      mobile,
      email,
      imageUrl,
      user_status,
    } = authorization.data;
    const user: User = { id, name, mobile, email, imageUrl, user_status };
    return {
      ...user,
      token: authorization.token,
      expiration,
    };
  }

  setCurrentUserDetails(authorization) {
    const { medication, allergies, pre_existing_conditions } = authorization;
    const userDetails = { medication, allergies, pre_existing_conditions };
    localStorage.setItem('userDetails', JSON.stringify(userDetails));
    // console.log(data,'data')
  }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem('token');
    // Check whether the token is expired and return
    // true or false
    return !this.jwtHelper.isTokenExpired(token);
  }
  signIn(creds: Credentials, redirect: string): Observable<AuthUser> {
    try {
      this.redirectUrl = this.redirectUrl || redirect;
      const { baseUrl, login } = this.env.getSettings().api;
      const url = `${baseUrl}${login}`;
      const headers = {
        loginReq: '', // Prevent AuthInterceptor from redirecting on 401.
      };
      return this.http.post(url, creds, { headers }).pipe(
        map((authorization) => {
          const user = AuthService.getUser(authorization);
          // this.setCurrentUserDetails(authorization['data']);
          this.setCurrentUser(user, authorization);
          return user;
        })
      );
    } catch (err) {
      console.log(err);
    }
  }
  setCurrentUser(user: AuthUser, authorization) {
    // if(authorization.data.user_status == 'in_process') {
    //   return;
    // }
    localStorage.setItem('user_status', authorization.data.user_status);
    localStorage.setItem('token', authorization.token);
    localStorage.setItem('horn_token', authorization.horn_token);
    const decoded = jwt_decode(authorization.token);
    const role = decoded['role'];
    const user_id = decoded['id'];
    localStorage.setItem('role', role);
    localStorage.setItem('user_id', user_id);
    const {
      medication,
      allergies,
      pre_existing_conditions,
    } = authorization.data;
    const userDetails = { medication, allergies, pre_existing_conditions };
    localStorage.setItem('userDetails', JSON.stringify(userDetails));
    const origin = window.location.origin;
    this.redirectOnSignIn = origin;
    if (authorization.data.user_status == 'in_process' && role !== 'admin') {
      this.redirectUrl = '/registration-view';
    } else {
      if (role === 'admin') {
        this.redirectOnSignIn += '/admin/dashboard';
        this.redirectUrl = '/admin/dashboard';
      } else if (role === 'patient') {
        this.redirectOnSignIn += '/patient/dashboard';
      } else if (role === 'doctor') {
        this.redirectOnSignIn += '/doctor/dashboard';
      } else if (role === 'healthstaff') {
        this.redirectOnSignIn += '/healthstaff/dashboard';
      }
    }
    const url = this.redirectOnSignIn;
    this.redirectOnSignIn = null;
    // window.location.href = url;

    //for cancel appointment
    let newUrl;
    if (window.location.search) {
      let params = window.location.search;
      newUrl = url + `${params.toString()}`;
      window.location.href = newUrl;
    } else {
      window.location.href = url;
    }
    ////
  }
  clearCurrentUser() {
    localStorage.removeItem('token');
    localStorage.removeItem('horn_token');
  }
  signOut() {
    this.redirectOnSignIn = null;
    localStorage.removeItem('redirectOnSignIn');
    localStorage.removeItem('user_id');
    this.clearCurrentUser();
    localStorage.removeItem('role');
    localStorage.removeItem('email');
    localStorage.removeItem('patient_Id');
    localStorage.removeItem('userDetails');
    localStorage.removeItem('appointment');
    localStorage.removeItem('user_status');
    localStorage.removeItem('isrimidyUser');
    this.ss.timerNotRequired = false;
  }

  isRimidyUser() {
    const isRimidy = localStorage.getItem('isrimidyUser');
    return isRimidy === 'true' ? true : false;
  }

  getUserDetails() {
    const decoded = jwt_decode(localStorage.getItem('token'));
    const { baseUrl, getUser } = this.env.getSettings().api;
    const url = baseUrl + getUser + '?user_id=' + decoded['id'];
    return this.http.get(url, {
      headers: new HttpHeaders({
        token: localStorage.getItem('token'),
      }),
    });
  }
}
