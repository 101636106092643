<p-toast></p-toast>
<app-loader [loading]="loading"></app-loader>

<p-table
  [columns]="tableHeaders"
  [value]="tableData"
  tableStyleClass="appointmentTable"
  [resizableColumns]="true"
  scrollHeight="180px"
  [autoLayout]="true"
  [scrollable]="scrollable"
>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns">{{ col.header }}</th>
    </tr>
  </ng-template>
  <ng-template
    pTemplate="body"
    let-rowData
    let-columns="columns"
    let-rowIndex="rowIndex"
  >
    <tr>
      <td *ngFor="let col of columns">
        <ng-container *ngIf="col.field == 'patient_name'">
          <span class="sm-font ls-28 font-openSans-semibold fc-dark">{{
            rowData[col.field] | titlecase
          }}</span>
        </ng-container>
        <ng-container *ngIf="col.field == 'doctor_name'">
          <span class="sm-font ls-28 font-openSans-semibold fc-dark">{{
            rowData[col.field] | titlecase
          }}</span>
        </ng-container>
        <ng-container *ngIf="col.field == 'date'">
          <span class="sm-font ls-28 font-openSans-regular fc-dark">{{
            rowData[col.field]
          }}</span>
        </ng-container>
        <ng-container *ngIf="col.field == 'time'">
          <span class="sm-font ls-28 font-openSans-regular fc-dark">{{
            rowData[col.field] | lowercase
          }}</span>
        </ng-container>
        <ng-container *ngIf="col.field == 'view'">
          <!-- <p-menu #menu [popup]="true" [model]="getMenuItemsForItem(rowData)" appendTo="body"
                        [style]="{'left':'613px','color':'#576af8'}" (click)="menu.hide()"></p-menu>
                    <div #body></div>
                    <span type="button" #buttonMenu pButton class="p-button-info p-button-text" icon="pi pi-ellipsis-v"
                        (click)="menu.toggle($event)"></span> -->
          <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            class="material-button"
          >
            <mat-icon>more_vert</mat-icon>
          </button>

          <mat-menu #menu="matMenu">
            <button
              (click)="joinAppointment(rowData)"
              mat-menu-item
              [disabled]="
                rowData.status == 'cancelled' ||
                rowData.status == 'completed' ||
                rowData.status == 'rescheduled'
              "
            >
              <span>Join </span>
            </button>
            <button (click)="getUserID(rowData)" mat-menu-item>
              <span>View </span>
            </button>
            <button
              *ngIf="!isRimidyUser"
              mat-menu-item
              (click)="confirmReschedule(rowData)"
              [disabled]="
                rowData.status == 'cancelled' ||
                rowData.status == 'completed' ||
                rowData.status == 'rescheduled'
              "
            >
              <span>Reschedule </span>
            </button>
            <button
              mat-menu-item
              (click)="confirmCancel(rowData)"
              [disabled]="
                rowData.status == 'cancelled' ||
                rowData.status == 'completed' ||
                rowData.status == 'rescheduled'
              "
            >
              <span>Cancel</span>
            </button>
          </mat-menu>
        </ng-container>
        <ng-container *ngIf="col.field == 'first_time'">
          <a
            (click)="getUserID(rowData)"
            class="fc-soft-blue cp xs-font ls-28 font-openSans-semibold"
            >View</a
          >
        </ng-container>
        <ng-container *ngIf="col.field == 'follow_up'">
          <a class="fc-soft-blue cp xs-font ls-28 font-openSans-semibold"
            >Follow Up</a
          >
        </ng-container>
        <ng-container *ngIf="col.field == 'status'">
          <span class="sm-font ls-28 font-openSans-regular fc-dark">{{
            rowData[col.field] | titlecase
          }}</span>
        </ng-container>
        <ng-container *ngIf="col.field == 'date_time'">
          <span
            class="sm-font ls-28 font-openSans-regular fc-dark"
            style="white-space: pre-line"
            >{{ rowData[col.field] | lowercase }}</span
          >
        </ng-container>
        <ng-container *ngIf="col.field == 'form_status'">
          <span class="sm-font ls-28 font-openSans-regular fc-dark">{{
            rowData[col.field]
          }}</span>
        </ng-container>
        <ng-container *ngIf="col.field == 'day'">
          <span class="sm-font ls-28 font-openSans-semibold fc-dark">{{
            rowData[col.field]
          }}</span>
        </ng-container>
        <ng-container *ngIf="col.field == 'date_no'">
          <span class="sm-font ls-28 font-openSans-semibold fc-dark">{{
            rowData[col.field]
          }}</span>
        </ng-container>
        <ng-container *ngIf="col.field == 'details'">
          <a class="fc-soft-blue cp xs-font ls-28 font-openSans-semibold">{{
            rowData[col.field]
          }}</a>
        </ng-container>
        <ng-container *ngIf="col.field == 'booked'">
          <span
            class="fc-soft-blue cp xs-font ls-28 font-openSans-semibold"
            (click)="navigateToList(rowData)"
            >{{ rowData[col.field] }}</span
          >
        </ng-container>
        <ng-container *ngIf="col.field == 'available'">
          <span
            class="fc-soft-blue cp xs-font ls-28 font-openSans-semibold"
            (click)="navigateToSM(rowData)"
            >{{ rowData[col.field] }}</span
          >
        </ng-container>
        <!-- <ng-container *ngIf="col.field == 'availabilitydetails'">
                    <a class="fc-soft-blue cp xs-font ls-28 font-openSans-semibold" (click)="navigateToSM(rowData)">Details</a>
                </ng-container> -->

        <ng-container *ngIf="col.field == 'approval'">
          <a
            class="fc-soft-blue cp xs-font ls-28 font-openSans-semibold"
            (click)="patientApproval(rowData)"
            >{{ rowData[col.field] }}</a
          >
        </ng-container>
        <ng-container *ngIf="col.field == 'no'">
          <div class="sm-font ls-28 font-openSans-semibold fc-dark">
            {{ rowIndex + 1 }}
          </div>
        </ng-container>
      </td>
    </tr>
  </ng-template>
</p-table>
