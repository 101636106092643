<app-loader [loading]="loading"></app-loader>
<p-toast></p-toast>

<div class="mt-20">
    <h3>Edit Slots</h3>

    <div class="mb-20" *ngIf="role == 'admin'">
        <h6>Clinician</h6>
        <!-- (onChange)="getDoctorID($event.value)" -->
        <p-dropdown [options]="doctorList" filter="true" [(ngModel)]="selectedDoctor" placeholder='Select Clinician'>
        </p-dropdown>
    </div>

    <ngb-datepicker #dp [(ngModel)]="model" [displayMonths]="2" [dayTemplate]="t" outsideDays="hidden"
        (dateSelect)="onDateSelection($event)"></ngb-datepicker>
    <ng-template #t let-date let-focused="focused">
        <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
            [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
            (mouseleave)="hoveredDate = null">
            {{ date.day }}
        </span>
    </ng-template>


    <div>
        <h4 class="mt-20">
            Existing Time Slots
            <button *ngIf="availablityList.length" class="btn btn-outline-primary ml-5" (click)="discardAll()">Discard
                all Slots</button>
        </h4>
        <div class="d-inline" *ngIf="">
            <div class="ptd-10">
                <input type="radio" id="single_edit" name="time_edit" value="single" [(ngModel)]="edit_type">
                <label for="single">Edit single slot</label>

                <input type="radio" class="ml-5" id="multiple_edit" name="time_edit" value="multiple"
                    [(ngModel)]="edit_type">
                <label for="multiple" class="mr5">Edit Date Range slot</label><br>
            </div>
        </div>
        <ng-container *ngIf="edit_type == 'single'">
            <b *ngIf="!availablityList.length && userId">Availability of doctor not set for given date</b>
            <div *ngFor="let slot of availablityList">
                <p>Date: <b>{{dateConversion(slot.date) }}</b></p>
                <div class="col-12 row">
                    <div class="col-sm-3">
                        <label>Start Time</label> {{timeConversion(slot.start_time)}}
                        <ngb-timepicker *ngIf="isEdit && slot.availability_id == edit_availablity_id"
                            [(ngModel)]="slot.edit_start" [meridian]="meridian"></ngb-timepicker>

                    </div>
                    <div class="col-sm-3">
                        <label>End Time</label> {{timeConversion(slot.end_time)}}
                        <ngb-timepicker *ngIf="isEdit && slot.availability_id == edit_availablity_id"
                            [(ngModel)]="slot.edit_end" [meridian]="meridian"></ngb-timepicker>

                    </div>
                    <div class="col-sm-3">
                        <button *ngIf="!isEdit && false" class="btn btn-primary mr5 btn-sm"
                            (click)="editSlot(slot)">Edit</button>

                        <button *ngIf="isEdit && slot.availability_id == edit_availablity_id"
                            class="btn btn-primary mr5 btn-sm" (click)="uplateSingleSlot(slot)">Save</button>
                        <button *ngIf="isEdit && slot.availability_id == edit_availablity_id"
                            class="btn btn-outline-primary btn-sm" (click)="isEdit = !isEdit">Cancel</button>

                        <button *ngIf="!isEdit" class="btn btn-outline-primary btn-sm"
                            (click)="deleteSlot(slot.availability_id)">Delete</button>

                    </div>

                </div>

            </div>
        </ng-container>

        <div *ngIf="edit_type != 'single'">
            <p>Selected time slot will be applied for the all selected dates</p>

            <div>
                <label>Start Time</label>
                <ngb-timepicker [(ngModel)]="multiple_startTime" [meridian]="meridian"></ngb-timepicker>

                <label>End Time</label>
                <ngb-timepicker [(ngModel)]="multiple_endTime" [meridian]="meridian"></ngb-timepicker>
            </div>

            <pre> Selected Time: {{arr | json}}</pre>

            <button class="btn btn-primary mr-5" type="button" (click)="addTime(multiple_startTime,multiple_endTime)">
                Add time</button>
            <button class="btn btn-outline-primary" type="button" (click)="clear()"> Clear</button>

            <div class="mt-20">
                <button class="btn btn-primary" (click)="updateMultipleSlots()">Update</button>
            </div>
        </div>
    </div>
</div>