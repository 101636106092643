import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {EnvironmentService} from '../../services/environment.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(
    private env: EnvironmentService,
    private http: HttpClient
  ) { }

  getDoctorDetails(): Observable<any> {
    const {baseUrl, admin} = this.env.getSettings().api;
    const url = `${baseUrl}${admin.getDoctors}`;
    return this.http.get(url);
  }

  // getSpecialty(): Observable<any> {
  //   const {baseUrl,getSpecialty} = this.env.getSettings().api;
  //   const url = `${baseUrl}${getSpecialty}`;
  //   return this.http.get(url, {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'token': localStorage.getItem('token')
  //     })
  //   });
  // }

  register(data, role) {
    const { baseUrl, register } = this.env.getSettings().api
    const url = baseUrl + register  + '?user_role=' + role;
    return this.http.post(url, data, {
      // headers: new HttpHeaders({
      //   'Content-Type': 'application/json'
      // })
    });
  }

  getAppointmentList(from_date,to_date) {
    console.log("From is " + from_date + "To is " + to_date);
    const {baseUrl,appointment} = this.env.getSettings().api;
    const url = `${baseUrl}${appointment}?from_date=${from_date}&to_date=${to_date}`;
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      })
    });
  }

  getDoctorAppointmentList(id,from_date,to_date) {
    const {baseUrl,appointment} = this.env.getSettings().api;
    const url = `${baseUrl}${appointment}?from_date=${from_date}&to_date=${to_date}&id=${id}`;
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      })
    });
  }

  getCountries() {
    return this.http.get('/assets/country.json')
  }

  getAppointmentDetails(id) {
    const { baseUrl, appointment } = this.env.getSettings().api
    return this.http.get(`${baseUrl}${appointment}/${id}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      })
    });
  }

  getPatientAppointments(user_id,from_date, to_date) {
    // query params
    // const user_id = localStorage.getItem('user_id');
    // const { baseUrl, appointment } = this.env.getSettings().api
    // const url = baseUrl + appointment + '/patient' + '/'  + user_id +'?from_date=' + from_date + '&to_date=' + to_date ;
    // return this.http.get(url, {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/json',
    //     'token': localStorage.getItem('token')
    //   })
    // });
  }

  createAppointment(data, doc_id) {
    const { baseUrl, appointment } = this.env.getSettings().api
    const url = baseUrl + appointment + `?doctorId=${doc_id}`;
    return this.http.post(url, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      })
    });
  }
}
