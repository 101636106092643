<p-toast></p-toast>
<app-loader [loading]="loading"></app-loader>
<div class="calPadding sm-height" *ngIf="(role == 'admin'  || role == 'healthstaff') && (!proceedFlag && !sharedService.homeFlag)">
    <div>
        <div class="font-openSans-semibold fs-26 fc-dark-grey-blue ls-89">Schedule Manager </div>
        <div class="font-openSans-regular fs-14 fc-twilight ls-03 mt-10">Manage Clinician's teleconsulting availability,
            and charges for
            bookings.</div>
    </div>
    <div class="mt-30 bc-pale-grey">
        <div class="height-50 border p-30">
            <h4 class="font-openSans-semibold fs-15 fc-dark ls-03">Select Clinician</h4>
            <div class="font-openSans-regular fs-14 fc-slate-grey mb-20">Please select a clinician from the list</div>
            <div class="font-openSans-regular xs-font fc-slate-grey">Name</div>
            <p-dropdown [options]="doctorList" filter="true" [(ngModel)]="sharedService.selectedDoctor"  appendTo="body"
                placeholder='Select Clinician' (onChange)="getSelectedDoctor($event.value)"
                [style]="{'font-size' : '14px' , 'border-radius' : '10px', 'height' : '45px','font-family': 'Open Sans' }">
            </p-dropdown>
        </div>
        <div class="mt-10">
            <button type="btn" (click)="proceed()"
                class="fr next-btn fc-white fs-14 font-openSans-semibold br-10 bc-soft-blue">Proceed</button>
        </div>
    </div>

</div>

<div class="setClass" *ngIf="(role == 'doctor') || proceedFlag  || sharedService.homeFlag">
    <div class="font-openSans-semibold fs-26 fc-dark-grey-blue ls-89 pl-10">Schedule Manager</div>
    <div class="font-openSans-regular fs-14 fc-twilight ls-03 mb-10 pl-10">Manage Clinician's teleconsulting
        availability, and charges for
        bookings.</div>
    <div class=" bc-pale-grey">
        <div class="pl-10 font-openSans-semibold xs-font fc-dark-grey-blue pt-20 cp" *ngIf="role == 'admin' || role == 'healthstaff'">
            <i class="fa fa-arrow-circle-o-left" aria-hidden="true" (click)="navSelectClinician()"></i>
            <span type="btn" (click)="navSelectClinician()">Back</span>
        </div>
        <div class="row calCss d-flex">
            <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12 fc-class">
                <div>
                    <h6 class="font-openSans-semibold fs-15 ls-03 fc-dark">Select Date(s)</h6>
                    <h6 class="font-openSans-regular fs-14 fc-slate-grey">Select the days to manage availability</h6>
                </div>
                <!-- (eventRender)="onEventRender($event)" (eventMouseover)="onEventMouseOver($event)"
                (eventMouseout)="onEventMouseOut()" -->
                <full-calendar [options]="calendarOptions"></full-calendar>
            </div>
            <!-- availability-details -->
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 ">

                <div>
                    <div *ngIf="role == 'admin' || role == 'healthstaff'" class="font-openSans-semibold mb-10 border bc-white p10 br-10 mt-10">
                        <div class="d-flex">
                            <div class="col-6 font-openSans-regular fs-14 fc-twilight ls-03 op-06">Name</div>:
                            <div class="col-6 font-openSans-semibold fs-14 fc-twilight ls-03">Dr.{{doctorName}}</div>
                        </div>
                        <div class="d-flex">
                            <div class="col-6 font-openSans-regular fs-14 fc-twilight ls-03 op-06">Specialty</div>:
                            <div class="col-6 font-openSans-semibold fs-14 fc-twilight ls-03">{{Specialty}}</div>
                        </div>

                    </div>

                    <p-tabView styleClass="br-10" (onChange)="handleChange($event.index)">
                        <p-tabPanel header="View Availability">
                            <div *ngIf="listOfAvailabilities.length > 0">
                                <div *ngFor="let list of listOfAvailabilities">
                                    <p-card>
                                        <ng-template pTemplate="header">
                                            <div class="font-openSans-semibold fs-14 ls-03 fc-dark">
                                                {{list['start_time']}} - {{list['end_time']}} {{list['date']}}
                                            </div>
                                        </ng-template>
                                        <div class="d-flex">
                                            <div class="col-8 font-openSans-regular fs-14 fc-twilight ls-03 op-06">Visit
                                                Duration</div>:
                                            <div class="col-4 font-openSans-semibold fs-14 fc-twilight ls-03">
                                                {{list['visit_duration']}}</div>
                                        </div>
                                        <ng-template pTemplate="footer">
                                            <div style="color:#0071c5" class="font-openSans-semibold xs-font pl-15 cp"
                                                (click)="showDeletePopUp(list)">Delete</div>
                                        </ng-template>
                                    </p-card>
                                </div>
                            </div>
                            <div *ngIf="listOfAvailabilities.length == 0">
                                <p-card>
                                    There are no slots set
                                </p-card>
                            </div>
                        </p-tabPanel>
                        <p-tabPanel header="Set Availability">
                            <h6 class="font-openSans-regular xs-font fc-slate-grey pb-10">Visit Duration</h6>
                            <span class="d-flex xs-font">
                                <input type="radio" id="fifteenmin" name="duration" value="15" [checked]="checked"
                                    (change)="handleClick($event.target.value)" [(ngModel)]="duration">
                                <label for="15" class="xs-font font-openSans-regular fc-dark">15 mins</label><br>
                                <input type="radio" id="thirtymin" name="duration" value="30" class="ml-10"
                                    (change)="handleClick($event.target.value)" [(ngModel)]="duration">
                                <label for="30" class="xs-font font-openSans-regular fc-dark">30 mins</label><br>
                                <input type="radio" id="fourtyfivemin" name="duration" value="45" class="ml-10"
                                    (change)="handleClick($event.target.value)" [(ngModel)]="duration">
                                <label for="45" class="xs-font font-openSans-regular fc-dark">45 mins</label>
                                <input type="radio" id="min" name="duration" value="other" class="ml-10"
                                (change)="handleClick('other')" [(ngModel)]="duration">
                            <label for="other" class="xs-font font-openSans-regular fc-dark">Other</label><br>
                            </span>
                            <div class="d-flex font-openSans-regular pt-10" *ngIf="showDuration">
                                <input type="text" (keypress)="isNumber($event);" 
                                    class="next-btn form-control mb-0 br5 fs-14 font-openSans-regular fc-dark ls-39 br-10"
                                    placeholder="Enter duration" maxlength="10" name="Contact" id="Contact"
                                    (change)="getMinutes($event.target.value)">
                                <span class="fs-14 font-openSans-regular pl-10 pt-20">mins</span>
                            </div>
                            <div class="sm-font fc-red font-openSans-regular" *ngIf="showMsg">Duration should be between 15-200 minutes</div>
                            <!-- <div *ngFor="let slot of listOfSlots" class="font-openSans-semibold"> -->
                            <div class="font-openSans-regular xs-font fc-slate-grey pt-20 pb-10">First Appointment</div>
                            <p-dropdown [options]="times" [(ngModel)]="selectedStartTime" placeholder="Start Time" appendTo="body"
                                [style]="{'background-color':'white !important','border-color':'#e6f0ff','width': '173px','height':'45px', 'border-radius': '10px'}">
                            </p-dropdown>
                            <div class="font-openSans-regular xs-font fc-slate-grey pb-10 pt-20">Last Appointment</div>
                            <p-dropdown [options]="times" [(ngModel)]="selectedEndTime" placeholder="End Time" appendTo="body"
                                [style]="{'background-color':'white !important','border-color':'#e6f0ff','width': '173px','height':'45px', 'border-radius': '10px'}">
                            </p-dropdown>
                            <!-- <span class="cp ml-20" (click)="addNewAvailability(slot)">+</span> -->
                            <!-- </div> -->
                            <div class="mt-30 save-btn" >
                                <button type="btn btn-primary" [disabled]="showMsg"
                                    class="input-btn br-10 bc-soft-blue btn pull-right fs-14 font-openSans-semibold fc-white"
                                    (click)="setAvailability()">Save</button>
                                <!-- <button type="btn btn-primary"
                                    class="input-btn br-10 btn bc-white border-cloudy-blue fs-14 pull-right mr-10 font-openSans-semibold fc-cloudy-blue">Cancel</button> -->
                            </div>
                        </p-tabPanel>
                    </p-tabView>


                </div>
            </div>
        </div>
    </div>

</div>

<!-- delete modal starts -->

<div id="deleteSlotPopUp" class="modal" role="dialog" data-backdrop="static" data-keyboard="false">
    <!-- <div class="modal-dialog"> -->
    <!-- Modal content-->
    <div class="modal-content" id="modalcontent" style="width: auto;">
        <div class="modal-header">
            <h4 class="modal-title fs-26 font-openSans-semibold fc-button-color pl-13">Delete Availability</h4>
            <div class="modal-body fs-16 font-openSans-regular fc-slate-grey">
                This will remove the slots that were made available for booking.
            </div>
        </div>

        <div class="modal-footer">
            <button type="button" data-dismiss="modal"
                class="h-45 button-width input-btn btn br-10 bc-white border-cloudy-blue fs-14 pull-right mr-10 font-openSans-semibold fc-cloudy-blue cp">Close</button>
            <button type="button" data-dismiss="modal"
                class="h-45 input-btn btn fw-600 button-width bc-soft-blue fs-14 font-openSans-semibold fc-white  br-10 cp"
                (click)="delete()">Proceed</button>
        </div>
    </div>
    <!-- </div> -->
</div>
<!-- delete modal ends -->