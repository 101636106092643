<div>
    <div class="modal-content border-none">
        <div class="modal-header bc-soft-blue">
            <h4 class="modal-title font-openSans-semibold fc-white">Comments</h4>
        </div>
        <div class="modal-body commentsbody">
            <div class="m-10 fs-14 fc-twilight font-openSans-regular op-06">Please specify the comments in order to retry the submission.</div>
           
                <textarea [(ngModel)]="comment" [rows]="7" [cols]="30"
                placeholder="Comments..." class="form-group m-10 w-81 comments-div"></textarea>
        
        </div>
        <div *ngIf="commentsAddedFlag" class="font-openSans-regular pl-30 pb-15" style="color:red">
               Specify the comments to deny the user 
        </div>
        <div class="modal-footer">
            <button type="button"
            class="h-42 br-10 btn bc-white button-width border-cloudy-blue fs-14 pull-right mr-10 font-openSans-semibold fc-cloudy-blue" (click)="dismiss()">Canel</button>
            <button type="button"
            class="h-42 br-10 bc-soft-blue btn button-width pull-right fs-14 font-openSans-semibold fc-white" (click)="addComments(comment)"
            >Send</button>
            
        </div>
    </div>
</div>