import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.sass']
})
export class CommentsComponent implements OnInit {
  
  @Output() messageEvent = new EventEmitter<string>();
  comment: string = '';
  commentsAddedFlag: boolean = false;

  constructor(private activeModal: NgbActiveModal, private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  dismiss() {
		this.activeModal.close();
  }
  
  addComments(event){
    if(event){      
      this.messageEvent.emit(this.comment);
      this.commentsAddedFlag = false;
      this.dismiss();
    }else {      
      this.commentsAddedFlag = true;
      return;
    }
    
    
    
  }

}
