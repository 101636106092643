import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { SharedService } from '../shared.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AdminService } from 'src/app/features/admin/admin.service';
@Component({
  selector: 'app-appointment-details',
  templateUrl: './appointment-details.component.html',
  styleUrls: ['./appointment-details.component.sass'],
  providers: [MessageService,DatePipe]
})
export class AppointmentDetailsComponent implements OnInit, OnChanges {
  @Input() appointmentDetails;
  visit_type: any = '';
  role: string;
  user_id: string;
  constructor(private sharedService: SharedService, public datePipe: DatePipe,
    private router: Router, private messageService:MessageService,private adminService: AdminService) { }
  start_time: any;
  end_time: any;
  date: any;
  patient_name: any = '';
  patient_mrn: any = '';
  doctor_location: any = '';
  patient_allergies: any = '';
  patient_medication: any = '';
  patient_preexisting_conditions: any = '';
  doctor_specialty: any = '';
  referring_doctor: any='';

  ngOnInit(): void {
  }

  getUrl(){
    return "url('assets/images/default.svg')";
  }
  
  
  ngOnChanges() {
    this.role = localStorage.getItem('role');
    this.user_id = localStorage.getItem('user_id');
    const appt_id = localStorage.getItem('appointment');

    // console.log(this.appointmentDetails)
    if (this.appointmentDetails && this.appointmentDetails.appointment_id == appt_id) {
      let date;
      this.patient_name = this.appointmentDetails.patient_name;
      this.patient_mrn = this.appointmentDetails.patient_mrn;
      this.doctor_location = this.appointmentDetails.doctor.doctor_location;
      this.doctor_specialty = this.appointmentDetails.doctor.doctor_specialty;
      const start_time = this.sharedService.convertToStandardTime(this.appointmentDetails.start_time);
      const end_time = this.sharedService.convertToStandardTime(this.appointmentDetails.end_time);
      [date, this.start_time] = start_time.split(',');
      this.end_time = end_time.split(',')[1];
      this.date = this.datePipe.transform(date, 'MM/dd/yyyy');
      this.start_time = this.datePipe.transform(start_time, 'hh:mm');
      this.end_time = this.datePipe.transform(end_time, 'hh:mm a');
      this.referring_doctor = this.appointmentDetails.referring_doctor;
      this.visit_type = (this.appointmentDetails.visit_type == 'follow_up') ? 'Follow up' : 'First Time';
    }else {
      this.getDetails();
    }
  }

  getDetails() {
    const id = localStorage.getItem('appointment');
    this.adminService.getAppointmentDetails(id).subscribe((res) => {
      this.appointmentDetails = res['data'];
      let date;
      this.patient_name = this.appointmentDetails.patient_name;
      this.patient_mrn = this.appointmentDetails.patient_mrn;
      this.doctor_location = this.appointmentDetails.doctor.doctor_location;
      this.doctor_specialty = this.appointmentDetails.doctor.doctor_specialty;
      const start_time = this.sharedService.convertToStandardTime(this.appointmentDetails.start_time);
      const end_time = this.sharedService.convertToStandardTime(this.appointmentDetails.end_time);
      [date, this.start_time] = start_time.split(',');
      this.end_time = end_time.split(',')[1];
      this.date = this.datePipe.transform(date, 'MM/dd/yyyy');
      this.start_time = this.datePipe.transform(start_time, 'hh:mm');
      this.end_time = this.datePipe.transform(end_time, 'hh:mm a');
      this.visit_type = (this.appointmentDetails.visit_type == 'follow_up') ? 'Follow up' : 'First Time';
      this.referring_doctor = this.appointmentDetails.referring_doctor;
      // console.log(res, 'getAppointmentDetails');
    }, (err) => {
      // this.messageService.clear();
      // this.messageService.add({ severity: 'warn', summary: 'Error', detail: err.error.message });
    })
  }

  join(id) {
    if (!this.sharedService.joinCall(this.appointmentDetails.start_time, this.appointmentDetails.end_time)) {
      this.messageService.clear();
      this.messageService.add({ severity: 'info', summary: 'Info', detail: 'Please join the call at the given time' });
      return;
    }
    localStorage.setItem('appointment',id);
    const horn_id = this.appointmentDetails.horn_channel_id;
    const hornClient = this.appointmentDetails.horn_client_id;
    // this.appointmentDetails['clientId'] = "9c1WYOkRFHvMxvCLtbUg"

    if(this.appointmentDetails.horn_client_id) {
      this.router.navigate([`/client/appointment`,this.appointmentDetails.horn_client_name, this.appointmentDetails.horn_client_id],{ queryParams: { page: 'video',id: horn_id,clientId: this.appointmentDetails.clientId, user_id: this.user_id,appointment_id:id } })
    } else {
      this.router.navigate([`${this.role}/appointment`],{ queryParams: { page: 'video',id: horn_id, user_id: this.user_id, appointment_id:id } });
    }
  }

  getAge(dob: any) {
    // const date = dob.toLocalString()
    const currentTime = new Date().getTime();
    const birthDateTime= new Date(dob).getTime();
    let difference = (currentTime - birthDateTime)
    const ageInYears = (difference/(1000*60*60*24*365)).toFixed(0);
    return ageInYears;
  }

  navigateToList(){
    const url = window.location.href.split('/');
    const role = url[3];
    const url1 = role + '/' + 'appointment' ;   
    if(role == 'admin' || role == 'doctor'){
      this.router.navigate([url1], { queryParams: { page: 'list' } });
    }else if(role == 'patient'){
      this.router.navigate([url1], { queryParams: { page: 'appointment-list' } });
    }
    
  }
}
