import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  settings;

  constructor(private _http: HttpClient) {
  }

  public getSettings(): any {
    return this.settings;
  }

  public load() {
    console.log('in load');
    return new Promise((resolve, reject) => {
      this._http
        .get(environment.configFile)
        .subscribe(response => {
          this.settings = response;
          resolve(true);
        });
    });
  }

}
export function EnvironmentServiceFactory(provider: EnvironmentService) {
  return () => provider.load();
}
