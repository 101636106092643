import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvironmentService } from 'src/app/services/environment.service';

@Injectable({
  providedIn: 'root'
})
export class DoctorService {

  appointment_id: any = '' ;
  doctorNotes: any = '';
  visit_type: boolean = false;

  constructor(private http: HttpClient, private env: EnvironmentService) { }

  createAppointment(data, doc_id) {
    const { baseUrl, appointment } = this.env.getSettings().api
    const url = baseUrl + appointment + `?doctorId=${doc_id}`;
    return this.http.post(url, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      })
    });
  }

  finishAppointment(id, data) {
    const { baseUrl, appointment } = this.env.getSettings().api
    const url = baseUrl + appointment + "/" + id;
    return this.http.patch(url, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      })
    });
  }

  getAppointmentDetails(id) {
    const { baseUrl, appointment } = this.env.getSettings().api
    const url = baseUrl + appointment + '/' + id;
    return this.http.get(url, {
      headers: new HttpHeaders({
        'token': localStorage.getItem('token')
      })
    });
  }  


  getListOfAppointments(from_date, to_date) {
    // query params
    const user_id = +localStorage.getItem('user_id'); 
    const { baseUrl, appointment } = this.env.getSettings().api
    const url = baseUrl + appointment + '/doctor' + '/'  + user_id +'?from_date=' + from_date + '&to_date=' + to_date ;
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      })
    });
  }
  
}
