import { Component, ElementRef, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-calendly',
  templateUrl: './calendly.component.html'
  // styleUrls: ['./calendly.component.sass']
})
export class CalendlyComponent implements OnInit {
  @ViewChild('container', { static: false }) container: ElementRef;

  constructor(private _renderer2: Renderer2, @Inject(DOCUMENT) private _document: Document) { }
  ngOnInit(): void {
    this.render()
  }

  render() {
    let script = this._renderer2.createElement('script');
    script.text = `
    Calendly.initInlineWidget({
        url: 'https://calendly.com/rasika-rajendra/30min?hide_event_type_details=2',
        parentElement: document.getElementById('calenderdiv'),   
    });
    `;
    this._renderer2.appendChild(this._document.body, script);
  }

  isCalendlyEvent(e) {
    console.log(e)
    return e.data.event &&
      e.data.event.indexOf('calendly') === 0;
  };

  // window.addEventListener(
  //   'message',
  //   function(e) {
  //   if (isCalendlyEvent(e)) {
  //     console.log(e.data);
  //   }
  // }
  // );
}
