import { Component, OnChanges, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  Router,
  Event,
  NavigationStart,
  ActivationStart,
  RouterEvent,
  NavigationEnd,
  RoutesRecognized,
} from '@angular/router';
import * as $ from 'jquery';
import { filter, pairwise } from 'rxjs/operators';
import { AuthService } from '../../security/auth.service';
@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.sass'],
})
export class SideNavComponent implements OnInit, OnChanges {
  public href: string = '';
  // loginFlag: boolean;
  loggedIn: boolean;
  hasToken: boolean;
  role: string = '';
  routerLink = [];
  disableLink: boolean = false;
  page = 'list';
  openNavTab: boolean = false;
  sideBarOpen = false;
  activeTab: string;

  patientRouter = [
    {
      routerLink: ['/patient/dashboard'],
      text: 'Home',
      icon: 'assets/images/home-icon.svg',
      iconActive: 'assets/images/home-icon-white.svg',
    },
    {
      routerLink: ['/registration-view'],
      text: 'My Profile',
      icon: 'assets/images/new-profile.png',
      iconActive: 'assets/images/profile-icon-white.svg',
    },
    {
      routerLink: ['/patient/appointment'],
      query: 'questionaire',
      text: 'Appointments',
      icon: 'assets/images/shape.svg',
      iconActive: 'assets/images/shape-icon-white.svg',
    },
  ];

  adminRouter = [
    {
      routerLink: ['/admin/dashboard'],
      text: 'Home',
      icon: 'assets/images/home-icon.svg',
      iconActive: 'assets/images/home-icon-white.svg',
    },
    {
      routerLink: ['/registration-view'],
      text: 'My Profile',
      icon: 'assets/images/new-profile.png',
      iconActive: 'assets/images/profile-icon-white.svg',
    },
    {
      routerLink: ['/admin/appointment'],
      query: 'list',
      text: 'Appointments',
      icon: 'assets/images/shape.svg',
      iconActive: 'assets/images/shape-icon-white.svg',
    },
    {
      routerLink: ['/admin/manage'],
      text: 'Manage Roles',
      icon: 'assets/images/manage-roles-icon.svg',
      iconActive: 'assets/images/manage-role-white.svg',
    },
    // { routerLink: ['/admin/clients'], text: 'Clients', icon:  'assets/images/manage-roles-icon.svg' , iconActive: 'assets/images/manage-role-white.svg' },
    {
      routerLink: ['/admin/availablity'],
      text: 'Schedule Manager',
      icon: 'assets/images/patient-forms-icon.svg',
      iconActive: 'assets/images/patient-form-icon-white.svg',
    },
  ];

  doctorRouter = [
    {
      routerLink: ['/doctor/dashboard'],
      text: 'Home',
      icon: 'assets/images/home-icon.svg',
      iconActive: 'assets/images/home-icon-white.svg',
    },
    {
      routerLink: ['/registration-view'],
      text: 'My Profile',
      icon: 'assets/images/new-profile.png',
      iconActive: 'assets/images/profile-icon-white.svg',
    },
    {
      routerLink: ['/doctor/appointment'],
      query: 'list',
      text: 'Appointments',
      icon: 'assets/images/shape.svg',
      iconActive: 'assets/images/shape-icon-white.svg',
    },
    {
      routerLink: ['/doctor/availablity'],
      text: 'Schedule Manager',
      icon: 'assets/images/patient-forms-icon.svg',
      iconActive: 'assets/images/patient-form-icon-white.svg',
    },
  ];

    healthStaffRouter = [
    {
      routerLink: ['/healthstaff/dashboard'],
      text: 'Home',
      icon: 'assets/images/home-icon.svg',
      iconActive: 'assets/images/home-icon-white.svg',
    },
    {
      routerLink: ['/registration-view'],
      text: 'My Profile',
      icon: 'assets/images/new-profile.png',
      iconActive: 'assets/images/profile-icon-white.svg',
    },
    {
      routerLink: ['/admin/appointment'],
      query: 'list',
      text: 'Appointments',
      icon: 'assets/images/shape.svg',
      iconActive: 'assets/images/shape-icon-white.svg',
    },
    {
      routerLink: ['/admin/availablity'],
      text: 'Schedule Manager',
      icon: 'assets/images/patient-forms-icon.svg',
      iconActive: 'assets/images/patient-form-icon-white.svg',
    },
  ];
  sideNav: boolean = true;
  topNav: boolean = true;
  isRimidyUser = false;

  constructor(
    private router: Router,
    private activateRoute: ActivatedRoute,
    private auth: AuthService
  ) {
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit(): void {
    const currentPath = window.location.pathname;
    if (location.href.includes('encryptedKey')) {
      this.auth.clearCurrentUser();
      this.routerLink = [
        {
          routerLink: ['/registration'],
          text: 'Profile',
          icon: 'assets/images/new-profile.png',
          iconActive: 'assets/images/profile-icon-white.svg',
        },
      ];
      return;
    }

    if (location.pathname === '/platform/client-appointment') {
      //dont show sidenav when 3rd party user
      this.sideNav = false;
      return;
    }
    if (location.pathname == '/platform/dashboard') {
      this.topNav = false;
      return;
    }

    if (!this.auth.isAuthenticated() && this.hasToken) {
      const pathName = window.location.pathname;
      const href = window.location.href;
      if (href.includes('isCancel') || href.includes('isReschedule')) {
        let endUrl = href.split('?');
        this.router.navigateByUrl('/login?' + endUrl[1]);
      } else {
        this.router.navigate(['/login']);
      }
      return;
    }
    // if(location.pathname.includes('login')) {
    //   localStorage.removeItem('token');
    //   return;
    // }
    this.isRimidyUser = this.auth.isRimidyUser();
    this.role = localStorage.getItem('role');
    this.onProfileChange();
    this.loggedIn = location.pathname.includes('login') ? false : true;
    this.activateRoute.params.subscribe((params) => {
      if (params.type) {
        this.page = params.type;
      }
    });
    // this.routerLink = [...this.patientRouter];
  }

  onProfileChange() {
    const user_status = localStorage.getItem('user_status');
    if (user_status == 'in_process' && this.role != 'admin') {
      this.routerLink = [
        {
          routerLink: ['/registration-view'],
          text: 'Profile',
          icon: 'assets/images/new-profile.png',
          iconActive: 'assets/images/profile-icon-white.svg',
        },
      ];
      return;
    }
    switch (this.role) {
      case 'admin':
        this.routerLink = [...this.adminRouter];
        this.auth.redirectUrl = '/admin/dashboard';
        // this.router.navigate(['/admin/dashboard']);
        break;
      case 'doctor':
        this.routerLink = [...this.doctorRouter];
        this.auth.redirectUrl = '/doctor/dashboard';
        if (this.isRimidyUser) {
          this.makeRimidyMenu();
        }
        // this.router.navigate(['/doctor/dashboard'],{ queryParamsHandling: 'preserve' });
        break;
      case 'patient':
        this.routerLink = [...this.patientRouter];
        this.auth.redirectUrl = '/patient/dashboard';
        // this.router.navigate(['/patient/dashboard'],{ queryParamsHandling: 'preserve' });
        break;
      case 'healthstaff':
        this.routerLink = [...this.healthStaffRouter];
        this.auth.redirectUrl = '/healthstaff/dashboard';
        // this.router.navigate(['/patient/dashboard'],{ queryParamsHandling: 'preserve' });
        break;
    }
  }

  makeRimidyMenu() {
    this.routerLink.splice(3, 2);
  }

  ngOnChanges() {
    this.loggedIn = location.pathname.includes('login') ? false : true;
    if (this.router.url.includes('?page=video')) {
      this.disableLink = true;
    } else {
      this.disableLink = false;
    }
    // if (!this.loginFlag) {
    //   this.loggedIn = true;
    // }
  }

  openNav() {
    $('#mySidenav').toggleClass('visibleClass');
    // console.log('open')
  }

  closeNav() {
    $('#mySidenav').toggleClass('visibleClass');
  }

  outsideClick() {
    $('#mySidenav').removeClass('visibleClass');
  }

  isActive(url): boolean {
    if (this.router.url.includes('?page=video')) {
      this.disableLink = true;
    } else {
      this.disableLink = false;
    }
    return this.router.url.includes(url);
  }

  selectedTab(tab: string) {
    this.activeTab = tab;
    if (tab == 'Patient Forms') {
      localStorage.removeItem('patient_Id');
    }
  }

  getClass() {
    if (this.router.url.includes('registration')) {
      return 'active';
    }
    return 'active';
  }

  onDeactivate(event) {
    this.router.events
      .pipe(
        filter((e: any) => e instanceof RoutesRecognized),
        pairwise()
      )
      .subscribe((e: any) => {
        if (e[0].url.includes('video')) {
          console.log(e);
          this.router.onSameUrlNavigation = 'reload';
        }
      });
    // window.confirm('Do u want to leave the call?')
  }

  onActivate(event) {
    window.scroll(0, 0);
    if (event.page) {
      this.page = event.page;
    }
    this.loggedIn = this.router.url.includes('login') ? false : true;
    this.disableLink = false;
    this.hasToken = localStorage.getItem('token') ? true : false;

    // if (!this.loginFlag) {
    //   this.role = sessionStorage.getItem('role') ? sessionStorage.getItem('role') : 'patient';
    // }
    if (this.router.url.includes('?page=video')) {
      this.disableLink = true;
    } else {
      this.disableLink = false;
    }
  }
}
