import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { EMPTY, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from './auth.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
  }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        if (req.headers.has('loginReq')) {
            // don't do anything to it
            req = req.clone({ headers: req.headers.delete('loginReq') });
            return next.handle(req);
        }
        const token: string = localStorage.getItem('token');
        if (token) {
            req = req.clone({
                setHeaders: {
                    token
                }
            });
        }
        return next.handle(req).pipe(
            catchError(error => {
                if (error && error instanceof HttpErrorResponse && error.status === 401) {
                    console.log(`Http Unauthorized error on ${error.url}`);
                    console.log("inside auth imterceptor login")
                    this.router.navigate(['/login']);
                    return EMPTY;
                }
                return throwError(error);
            }),
        );
    }
}
