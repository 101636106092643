import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Router ,ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.sass']
})
export class ConfirmationComponent implements OnInit {

  @Input() type: any ='';
  @Input() appointmentData: any = [];
  data: any = '' ;
  dataLoaded: boolean = false;
  title: string = '';

  constructor(private router: Router,private activateRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activateRoute.queryParams.subscribe((params) => {
      if(params.page == 'confirmation'){
        this.title = 'Confirmed';
      }else if(params.page == 'reschedule'){
        this.title = 'Rescheduled';
      }
      });
  }

  reset() {
   const role = localStorage.getItem('role');
   this.router.navigate([`/${role}/dashboard`])
  }
}
