import { Component, OnInit, Renderer2, Inject, OnChanges, Input, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import * as $ from 'jquery';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import * as moment from 'moment';
import { SharedService } from '../shared.service';

declare var $: any;
@Component({
  selector: 'app-horn-component',
  templateUrl: './horn-component.component.html',
  styleUrls: ['./horn-component.component.sass']
})
export class HornComponentComponent implements OnInit, OnDestroy {
  channelId: any;
  clientId = 'biwKrFISC9uHa5PFe9SP';//'ZMUq8PVDvMuSfpnzhO6p'; //teleview md client id
  script: any;
  start_time: any;
  end_time: number;
  loged_user_id: any;
  url_user_id: any;
  guestUser: boolean = false;
  appointment_id: number;
  appointmentDetails: any = {};
  showPayment: boolean = false;
  loading: boolean = false;
  @Input() userType ?= '';

  constructor(private _renderer2: Renderer2, @Inject(DOCUMENT) private _document: Document,
    private activeRoute: ActivatedRoute, private router: Router, private location: Location, public sharedService: SharedService) {
    this.loged_user_id = localStorage.getItem('user_id');
    this.activeRoute.queryParams.subscribe((params) => {
      if (params.id) {
        this.channelId = params.id;
      }
      if (params.start_time) {
        this.start_time = +params.start_time;
      }
      if (params.end_time) {
        this.end_time = +params.end_time;
      }
      if (params.user_id) {
        this.url_user_id = +params.user_id
      }
      if (params.appointment_id) {
        this.appointment_id = +params.appointment_id;
      }
    });
    this.activeRoute.queryParams.subscribe((params) => {
      if (params.clientId) {
        this.clientId = params.id;
      }
    });
    this.activeRoute.params.subscribe((params) => {
      if (params.client) {
        this.clientId = params.id;
      }
    });
    this.guestUser = localStorage.getItem('role') === 'doctor' ? false : true;
  }

  getUrl(): string {
    const baseUrl = location.origin;
    const redirectUrl = localStorage.getItem('redirectOnSignIn');
    // if the client is not televiewmd, pass the client id the redirect url
    if (this.clientId !== 'biwKrFISC9uHa5PFe9SP') {
      return `${baseUrl}${redirectUrl}?clientId=${this.clientId}`
    } else {
      return `${baseUrl}${redirectUrl}`;
    }
  }

  getAppointmentDetails() {

  }

  navigateBack() {
    this.showPayment = false;
    this.location.back();
  }

  async ngOnInit() {
    this.sharedService.timerNotRequired = true;
    const role = localStorage.getItem('role')
    if(role === 'patient') {
      this.loading = true;
      setTimeout(() => {
        this.loading = false
      }, 1000);
      const details = await this.sharedService.getAppointmentDetails(this.appointment_id).toPromise()
      // console.log(details['data'])
      this.appointmentDetails = details['data'];
      if (this.appointmentDetails['payment_status'] !== 'completed' && !this.appointmentDetails.horn_client_id) {
        this.showPayment = true;
      }
      if (this.showPayment) {
        return;
      }
    }
    //check login user and url user is same
    // if (this.url_user_id != this.loged_user_id) {
    //   return;
    // }
    // //check the joining time is valid
    // if (this.start_time && !this.sharedService.joinCall(this.start_time, this.end_time)) {
    //   return;
    // }
    let hornToken   = localStorage.getItem('horn_token');
    const href      = window.location.href;
    let endUrl      = href.split('?');
    let guestMeetingUrl = window.location.origin + '/appointment?' + endUrl[1];
    if (this.userType === 'guest') {
      hornToken = '';
    }
    this.script = this._renderer2.createElement('script');
    this.script.type = `text/javascript`;
    this.script.text = `
    co.horn.widget.run({
      channel: '${this.channelId}',
      clientId: '${this.clientId}',
      requireTerms: true,
      idToken: '${hornToken}',
      // skipLobby: true,
      // guestUsername: 'guest_user_name',
      showLobbyControls: true,
      // showAvatars: false,
      showAnimalPlaceholders: false,
      showInvitation: true,
      postMeetingURL: '${this.getUrl()}',
      joinMeetingURL: '${guestMeetingUrl}',
      requireTerms: false,
      customTexts: {
        LeaveMeetingConfirmation: 'Are you sure you want to end the consultation?',
      },
      brandLogoURL:'https://allegiancehealth.televiewmd.com/assets/images/emd-logo.png',
      customIcons: {
        Add: 'https://allegiancehealth.televiewmd.com/assets/images/checkmark.png',

      },
      customColors: {
        BackgroundMobilePanelHeader: '#012368',
        MenuRoomNameMobile: '#012368',
        TextAccent: '#323a6e',
        ButtonPrimary: '#012368'
      },
      display: {
        container: 'horn-container',
    }})
    `;
    this._renderer2.appendChild(this._document.body, this.script);
  }

  ngOnDestroy() {
    // remove script if present in dom
    if (this.script) {
      this._renderer2.removeChild(this._document.body, this.script);
    }
    // setTimeout(() => {
    // }, 100);
    //reload to disconnect the horn plugin
    window.location.reload();
  }


}
