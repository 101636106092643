<div class="topHeader">
    <span class="televiewLogo" [ngClass]="{'cp': authService.isAuthenticated()}">
        <img src="assets/images/emd-logo-small.png" (click)="navigateHome()" >
    </span>
    <div class="sm-font d-inline text-center pull-right header-img">
        <!-- <p-dropdown [options]="language" [(ngModel)]="defaultLang" styleClass="langDropdown mr-20"></p-dropdown> -->

        <span class="dropdown pull-right" *ngIf="hasToken"  title="Logout">
            <span id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <b class="mr-10 fc-dark fs-14 userName cp">{{ss.user_name | titlecase}}</b>
                 <!-- <img *ngIf="!ss.imageURL" width="40" height="40" src="assets/images/default.svg" class="mr-10 img-circle cp">  -->
                <ngx-avatar  *ngIf="ss.imageURL == null" size="40" value="{{ss.first_name[0] + ss.last_name[0]}}">
                </ngx-avatar>
                <!-- <img *ngIf="ss.imageURL" width="40" height="40" [src]="ss.imageURL" class="mr-10 img-circle cp"> -->
                <div class="mr-10 img-circle cp wh-40 headerImage t-10" *ngIf="ss.imageURL" [ngStyle]="{'background-image':  'url(' + (ss.imageURL) + ')'}"></div>
                <i class="fa fa-chevron-down headerDropdown cp" aria-hidden="true"></i>
            </span>
            <div class="dropdown-menu dropdown-menu-right header-dd" aria-labelledby="dropdownMenuButton">
                <a class="userNameDrop dropdown-item"> {{ss.user_name | titlecase}}</a>
                <a class="dropdown-item" routerLink="/login" (click)="authService.signOut()">Logout</a>
            </div>
        </span>

    </div>
</div>

