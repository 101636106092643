import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvironmentService } from 'src/app/services/environment.service';
import * as moment from 'moment';
import { CurrencyPipe } from '@angular/common';
import * as momentTz from 'moment-timezone';
@Injectable({
  providedIn: 'root'
})
export class SharedService {

  user_name: any = '';
  imageURL: any = '';
  first_name: any = ''
  last_name: any = '';
  attachmentImgs: any = [];
  files: any[] = [];
  selectedDoctor: any;
  homeFlag: boolean = false;
  newDate1: any;
  selectDoctor: any;
  date: any;
  CalendarDate: any;
  timerNotRequired: boolean = false;
  modalRef: any;
  // dateAfterThreeMonths1: any;

  constructor(private http: HttpClient, private env: EnvironmentService) { }

  //Date format: MM/DD/yyyy ex: 01/23/2020
  //Time format: 24 hrs ex: 15:30
  convertToUnixTimeStamp(date: any, time: string) {
    const dateVal = moment(date).format("YYYY/MM/DD");
    const dateString = `${dateVal} ${time}:00`;
    //const unixDateTime = new Date().valueOf();
    const unixDateTime = new Date(dateString).valueOf();
    return unixDateTime;
  }

  convertToStandardTime(timestamp) {
    const localDate = new Date(timestamp).toLocaleString()
    console.log("Converted " + timestamp + " to " + localDate.toString());
    return localDate;
  }

  getLastDay(date){
    var lastday = date.getDate() - (date.getDay() - 1) + 6;
    return new Date(date.setDate(lastday));
  }

  getUpcomingAppointment(appointment_list: any[]) {
    const currTime = new Date().valueOf();
    if (appointment_list.length === 1) {
      return appointment_list[0].start_time > currTime ? appointment_list[0] : undefined;
    } else {
      const getFirst = (arr, predicate) => {
        for (const val of arr) {
          if (predicate(val)) return val;
        }
        return undefined;
      }
      const appointment = getFirst(appointment_list.sort((a, b) => a.start_time - b.start_time), x => x.start_time > currTime);
      return appointment;
    }
  }

  joinCall(start_time, end_time) {
    return true;
    const currTime = new Date().valueOf();
    var dt = new Date(start_time)
    dt.setMinutes(dt.getMinutes() - 15); //join before 15mins
    // const  [cur, cal, st, end] = [new Date(currTime), new Date(dt), new Date(start_time), new Date(end_time)]
    // console.log({cur, cal, st,end})
    if (start_time === currTime) {
      return true
    } else if (currTime >= dt.valueOf() && currTime < end_time) {
      return true;
    } else if (currTime >= start_time && currTime < end_time) {
      return true;
    } else {
      return false;
    }
  }

  getTimeZone(): string {
    return momentTz.tz.guess();
  }


  getTimeZoneName(): string {
    const d = new Date();
    const n = String(String(d).split("(")[1]).split(")")[0];
    return n;
  }

  getListOfDoctors() {
    const { baseUrl, getUsers } = this.env.getSettings().api
    const url = baseUrl + getUsers + '?user_role=doctor';
    return this.http.get(url, {
      headers: new HttpHeaders({
        'token': localStorage.getItem('token')
      })
    });
  }

  getActiveDoctors() {
    const { baseUrl, getUsers } = this.env.getSettings().api
    const url = baseUrl + getUsers + '?user_role=doctor&user_status=active';
    return this.http.get(url, {
      headers: new HttpHeaders({
        'token': localStorage.getItem('token')
      })
    });
  }

  getListOfPatients() {
    const { baseUrl, getUsers } = this.env.getSettings().api
    const url = baseUrl + getUsers + '?user_role=patient';
    return this.http.get(url, {
      headers: new HttpHeaders({
        'token': localStorage.getItem('token')
      })
    });
  }


  searchPatient(value) {
    const { baseUrl, searchPatient } = this.env.getSettings().api
    const url = baseUrl + searchPatient + '?search_name=' + value + '&user_role=patient' + '&user_status=active';
    return this.http.get(url, {
      headers: new HttpHeaders({
        'token': localStorage.getItem('token')
      })
    });
  }


  getActivePatients() {
    const { baseUrl, getUsers } = this.env.getSettings().api
    const url = baseUrl + getUsers + '?user_role=patient&user_status=active';
    return this.http.get(url, {
      headers: new HttpHeaders({
        'token': localStorage.getItem('token')
      })
    });
  }

  getListOfPatientsOnStatus(status) {
    const { baseUrl, getUsers } = this.env.getSettings().api
    const url = baseUrl + getUsers + '?user_role=patient&user_status=' + status;
    return this.http.get(url, {
      headers: new HttpHeaders({
        'token': localStorage.getItem('token')
      })
    });
  }

  getListOfDoctorOnStatus(status) {
    const { baseUrl, getUsers } = this.env.getSettings().api
    const url = baseUrl + getUsers + '?user_role=doctor' + '&user_status=' + status;
    return this.http.get(url, {
      headers: new HttpHeaders({
        'token': localStorage.getItem('token')
      })
    });
  }

  getListOfAdminOnStatus(status) {
    const { baseUrl, getUsers } = this.env.getSettings().api
    const url = baseUrl + getUsers + '?user_role=admin' + '&user_status=' + status;
    return this.http.get(url, {
      headers: new HttpHeaders({
        'token': localStorage.getItem('token')
      })
    });
  }

  getListOfHealthStaffOnStatus(status) {
    const { baseUrl, getUsers } = this.env.getSettings().api
    const url = baseUrl + getUsers + '?user_role=healthstaff' + '&user_status=' + status;
    return this.http.get(url, {
      headers: new HttpHeaders({
        'token': localStorage.getItem('token')
      })
    });
  }

  approveUser(id, obj) {
    const { baseUrl, approveUser } = this.env.getSettings().api
    return this.http.patch(`${baseUrl}${approveUser}/${id}`, obj, {
      headers: new HttpHeaders({
        'token': localStorage.getItem('token')
      })
    });
  }

  getListOfAppointments(user_id, from_date, to_date) {
    const { baseUrl, appointment } = this.env.getSettings().api
    let url;
    if(user_id){
      url = baseUrl + appointment + '/doctor' + '/' + user_id + '?from_date=' + from_date + '&to_date=' + to_date;
    }else{
      url = baseUrl + appointment + '?from_date=' + from_date + '&to_date=' + to_date;
    }
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      })
    });
  }

  createClient(clientName) {
    const { baseUrl, createClient } = this.env.getSettings().api
    let url = baseUrl + '/' + createClient;
    return this.http.post(url, clientName, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      })
    });
  }

  getClients() {
    const { baseUrl, getClients } = this.env.getSettings().api
    let url = baseUrl + '/' + getClients;
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      })
    });
  }

  setAvailability(id, slotData) {
    // console.log(slotData)
    const { baseUrl, availability } = this.env.getSettings().api;
    return this.http.post(`${baseUrl}${availability}/${id}`, slotData, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      })
    });
  }

  getAvailability(id, start_date, end_date) {
    const { baseUrl, availability } = this.env.getSettings().api
    const url = baseUrl + availability + '/' + id + '?from_date=' + start_date + '&to_date=' + end_date;
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      })
    });
  }

  getAvailabilityforDates(id,data) {
    const { baseUrl, availabilityForDates } = this.env.getSettings().api
    const url = baseUrl + availabilityForDates + '/' + id;
    return this.http.post(url, data,{
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      })
    });
  }

  getDoctorAvailability(id, start_date, end_date) {
    const { baseUrl, getDoctorAvailability } = this.env.getSettings().api
    const url = baseUrl + getDoctorAvailability + '/' + id + '?from_date=' + start_date + '&to_date=' + end_date;
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      })
    });
  }



  updateSlot(id, data) {
    const { baseUrl, availability } = this.env.getSettings().api;
    return this.http.put(`${baseUrl}${availability}/${id}`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      })
    });
  }

  deleteSlots(id, data) {
    const { baseUrl, delAvailability } = this.env.getSettings().api;
    return this.http.post(`${baseUrl}${delAvailability}/${id}`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      })
    });
  }

  getAppointmentDetails(id) {
    // console.log("hi");

    const { baseUrl, appointment } = this.env.getSettings().api
    return this.http.get(`${baseUrl}${appointment}/${id}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      })
    });
  }


  cancelAppointment(id, status) {
    const { baseUrl, cancelAppointment } = this.env.getSettings().api
    return this.http.patch(`${baseUrl}${cancelAppointment}/${id}`,status, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      })
    });
  }

  rescheduleAppointment(id,data){
    const { baseUrl, rescheduleAppointment } = this.env.getSettings().api
    const url = baseUrl + rescheduleAppointment + '/' + id;
    return this.http.patch(url,data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      })
    });
  }

  deleteUser(id){
    console.log("Deleting user");
    const { baseUrl, deleteUser } = this.env.getSettings().api
    const url = baseUrl + deleteUser + "/" + id;
    return this.http.delete(url,{
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      })
    });
  }

  getDoctorSlots(id,from_date,to_date){
    const { baseUrl,  getDoctorSlots} = this.env.getSettings().api
    const url = baseUrl + getDoctorSlots + '/' + id + '?from_date='+ from_date + '&to_date=' + to_date;
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      })
    });
  }

}
