<app-header *ngIf="loggedIn && topNav"></app-header>
<div
  id="menuBar"
  class="hambugerMenu cp"
  *ngIf="loggedIn && sideNav"
  (click)="openNav()"
>
  <i class="fa fa-bars" aria-hidden="true"></i>
</div>

<div>
  <!-- <div class="sidenav"> && !openNavTab" (click)="openNav()" -->
  <div class="sidenav" id="mySidenav" *ngIf="loggedIn && sideNav && hasToken">
    <!-- <div class="closemenu cp" *ngIf="loggedIn" (click)="closeNav()">
            <span class="fs-32">&times;</span>
        </div> -->
    <div
      class="fw-600 navItems"
      *ngIf="!hasToken && topNav"
      style="min-width: 180px"
    >
      <a
        [routerLink]="['/registration']"
        [class.active]="isActive('registration') || isActive('reset')"
      >
        <i class="mr-10 fa fa-user-circle-o fa-2x" aria-hidden="true"></i>
        <span>Profile</span></a
      >
    </div>
    <ng-container *ngIf="hasToken">
      <div
        class="fw-600 navItems"
        *ngFor="let router of routerLink; let i = index"
        (click)="openNav()"
      >
        <a
          [routerLink]="router.routerLink"
          routerLinkActive="{{ getClass() }}"
          *ngIf="router.text != 'My Profile' && !router.query"
          (click)="selectedTab(router.text)"
          class="router-nav"
        >
          <img class="mr-10 normal" src="{{ router.icon }}" />
          <img class="mr-10 active-icons" src="{{ router.iconActive }}" />
          <span class="t-04 fs-14 font-openSans-semibold">{{
            router.text
          }}</span></a
        >
        <a
          [routerLink]="router.routerLink"
          [class.active]="isActive('/registration')"
          *ngIf="router.text == 'My Profile' && !router.query"
          (click)="selectedTab(router.text)"
          class="router-nav"
        >
          <img class="mr-10 normal" src="{{ router.icon }}" />
          <img class="mr-10 active-icons" src="{{ router.iconActive }}" />
          <span class="t-04 fs-14 font-openSans-semibold">{{
            router.text
          }}</span></a
        >
        <!-- [class.disabled]="disableLink" -->
        <a
          *ngIf="router.query"
          [routerLink]="router.routerLink"
          [class.active]="isActive('/appointment')"
          [queryParams]="{ page: router.query }"
          (click)="selectedTab(router.text)"
          class="router-nav"
        >
          <img class="mr-10 normal" src="{{ router.icon }}" />
          <img class="mr-10 active-icons" src="{{ router.iconActive }}" />
          <span class="t-04 fs-14 font-openSans-semibold">{{
            router.text
          }}</span></a
        >
        <div class="Path-8"></div>
      </div>
    </ng-container>
  </div>

  <div
    id="mainDiv"
    [ngClass]="{ main: loggedIn && sideNav, platform: !sideNav }"
    (click)="outsideClick()"
  >
    <router-outlet
      (activate)="onActivate($event)"
      (deactivate)="onDeactivate($event)"
    ></router-outlet>
  </div>
</div>
