import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.sass'],
  providers: [MessageService]
})
export class ClientsComponent implements OnInit {
  selectedClient: any;
  client: any = '';
  loading: boolean = false;
  clients = [];

  constructor( private messageService: MessageService, public sharedService: SharedService) {
    // this.clients = [
    //   { name: 'Teleview MD', value: 'teleview' },
    //   { name: 'Rimidi', value: 'rimidi' }
    // ];
  }

  ngOnInit(): void {
    this.getListOfClients();
  }

  getSelectedClient(flag) {
    // console.log(this.client);
    if (flag == 'c') {
      this.client = '';
    } else if (flag == 's') {
      this.createClient();
    }
  }

  createClient() {
    this.loading = true;
    let obj = {
      "horn_client_name": this.client
    }
    this.sharedService.createClient(obj).subscribe((res) => {  
      this.loading = false;
      this.messageService.clear();
      this.messageService.add({ severity: 'success', summary: 'Success', detail: res['response']['message'] });
      this.client = '';

    }, (err) => {
      this.loading = false;
      this.client = '';
      this.messageService.clear();      
      this.messageService.add({ severity: 'warn', summary: 'Error', detail: err.error.message });
    })
  }

  getListOfClients(){
    this.loading = true;
    this.sharedService.getClients().subscribe((res) => {   
      this.loading = false;
      res['data']['results'].forEach(ele => {        
        // let obj =  { name: ele['name'], value: ele['name'] };  
        let obj = {};
        obj['name'] = ele['name'];
        obj['value'] = ele['name'];      
        this.clients.push(obj);
      });

    }, (err) => {
      this.loading = false;
    })
  }

}
