import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '../app/security/auth-guard.service';
const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: '',
    loadChildren: () => import('./layout/layout.module').then(mod => mod.LayoutModule)
  },
  {
    path: 'doctor',
    loadChildren: () => import('./features/doctor/doctor.module').then(mod => mod.DoctorModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'healthstaff',
    loadChildren: () => import('./features/healthstaff/healthstaff.module').then(mod => mod.HealthStaffModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'admin',
    loadChildren: () => import('./features/admin/admin.module').then(mod => mod.AdminModule),
    canActivate: [AuthGuardService]
  },
  // {
  //   path: 'client',
  //   loadChildren: () => import('./features/rimidi/rimidi.module').then(mod => mod.RimidiModule),
  //   canActivate: [AuthGuardService]
  // },
  {
    path: 'platform',
    loadChildren: () => import('./third-party/third-party.module').then(mod => mod.ThirdPartyModule),
    // canActivate: [AuthGuardService]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
