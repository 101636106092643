<app-loader [loading]="loading"></app-loader>
<p-toast></p-toast>

<div class="bc-white br-10">
    <div class="br-10">
        <h5 class="fc-dark-grey-blue fs-32 font-openSans-semibold book-appointment">Book an Appointment</h5>
        <h6 class="fs-14 fc-twilight pl-25 font-openSans ls-03 op-05">Please fill in the below details to continue</h6>
        <div class="cover-grid bc-pale-grey-three mt-20 mb-30 calendar-class">
            <div class="d-flex flexClass col-lg-12 col-xl-12 col-md-12 col-sm-12 p-0">
                <div class=" col-md-12 col-sm-12 leftSide bc-pale-grey pb-10"
                    [ngClass]="!isShow ?'col-lg-4 col-xl-4':'col-lg-4 col-xl-4'">
                    <div class="pt-30 pl-15" (click)="navigateToQuestionaire()" *ngIf="!isShow">
                        <span class="cp">
                            <i class="fa fa-arrow-circle-o-left" aria-hidden="true"></i>
                            Back</span>
                    </div>
                    <!-- <div class="pt-30 pl-15" (click)="isShow = false" *ngIf="isShow">
                        <span class="cp">
                            <i class="fa fa-arrow-circle-o-left" aria-hidden="true"></i>
                            Back</span>
                    </div> -->
                    <div class="col-sm-12 sm-font  pt-20 font-openSans-regular text-muted fw-600">{{userName |
                        titlecase}}</div>
                    <div class="col-sm-12">
                        <h4 class="font-openSans-semibold fc-dark fw-600 pt-10 ">TeleConsultation</h4>
                    </div>
                    <!-- <div class="col-sm-12 font-openSans-regular mt-15 text-muted fw-600 fs-14"><i
                            class="fa fa-clock-o mr-10 lh-20" aria-hidden="true"></i>
                        15 min</div> -->
                    <div class="col-sm-12 font-openSans-regular mt-15 text-muted fw-600 fs-14 d-flex"><i
                            class="fa fa-video-camera mr-10 t-03" aria-hidden="true"></i>
                        Web conferencing details provided upon confirmation
                    </div>
                    <div class="col-sm-12 font-openSans-regular mt-15 text-muted fw-600 fs-14 d-flex" *ngIf="isShow"><i
                            class="fa fa-calendar-o mr-10 t-04" aria-hidden="true"></i>
                        {{confirmTime | lowercase}} {{selectedDate | date: 'EEEE, MMMM d yyyy'}}</div>
                    <div class="col-sm-12 text-muted fs-14 mt-15 fw-600 font-openSans-regular  d-flex">
                        <i class="fa fa-globe mr-10 t-03" aria-hidden="true"></i>{{sharedService.getTimeZoneName()}}
                    </div>
                </div>

                <div class="col-lg-8 col-xl-8 col-md-12 col-sm-12 col-xs-12 mt-10 no-padding d-flex flexWrap bc-pale-grey-three "
                    *ngIf="!isShow">

                    <!-- <div class="col-lg-8 col-xl-8 col-md-12 col-sm-12 col-xs-12 calendar no-padding mb-20 d-inline">
                        <h6 class="dateLabel font-openSans-semibold sm-font fc-dark">Select a Date & Time</h6>
                        <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-xs-12 col-12">
                            <p-calendar  [style]="{'width':'100%'}" styleClass="text-center slotCalendar overflow-auto" [inputStyle]="{'width':'100%'}" [inline]="true" 
                            [(ngModel)]="selectedDate" dateFormat="mm/dd/yyyy" [disabledDates]="invalidDates"  [minDate]="minimumDate" [maxDate]="maxDate" (onSelect)="getAvailableTimeSlots($event)"></p-calendar>
                        </div>
                    </div> -->

                    <div class="col-lg-8 col-xl-8 col-md-12 col-sm-12 col-xs-12 calendar no-padding mb-20 d-inline">
                        <h6 class="dateLabel font-openSans-semibold sm-font fc-dark">Select a Date & Time</h6>
                        <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-xs-12 col-12">
                            <p-calendar [style]="{'width':'100%'}" styleClass="text-center slotCalendar overflow-auto"
                                [inputStyle]="{'width':'100%'}" [inline]="true" [(ngModel)]="selectedDate"
                                dateFormat="mm/dd/yyyy" [minDate]="minimumDate" [maxDate]="maxDate"
                                (onSelect)="getAvailableTimeSlots($event)"></p-calendar>
                        </div>
                    </div>

                    <div *ngIf="!isShow" class="col-lg-4 col-xl-4 col-md-12 col-sm-12 col-xs-12 no-padding">
                        <h6 class="dateLabel font-openSans-semibold sm-font fc-dark">
                            {{selectedDate | date: 'EEEE, MMMM d'}}</h6>
                        <div class="time">

                            <!-- <div class="plr-15">
                                <div>
                                    <label class="font-weight-bold">Start Time</label>
                                    <ngb-timepicker [(ngModel)]="booking_startTime" [meridian]="meridian"></ngb-timepicker>
                                </div>
                
                                <div>
                                    <label class="font-weight-bold">End Time</label>
                                    <ngb-timepicker [(ngModel)]="booking_endTime" [meridian]="meridian"></ngb-timepicker>
                                </div>
                                <button class="mt-15 btn btn-primary flexClass" (click)="confirmBooking()">Confirm</button>
                            </div> -->
                            <div *ngIf="hasSlots">
                                <div class="mt-10 d-flex col-lg-12 col-xl-12 col-md-12 col-sm-8 col-xs-8"
                                    *ngFor="let time of timeOption;let i = index">
                                    <button type="button" class="flexClass"
                                        [disabled]="checkPastTime(time) || slotAvaliable(time) "
                                        [ngClass]="time == selectedTime ?'btn btn-dark mr-10 confirmButton':'btn-block btn btn-outline-primary confirmButton'"
                                        (click)="selectTime(time)">{{time.starttime}}
                                    </button>
                                    <button *ngIf="time == selectedTime" type="button"
                                        class="btn btn-primary  flexClass" (click)="confirm(time)">{{reschedule ? 'Reschedule': 'Confirm'}}</button>
                                </div>
                            </div>

                            <div style="display: table;height: 300px;">
                            <div *ngIf="!hasSlots" style="display: table-cell;vertical-align: middle">
                                <h6 class="dfont-openSans-semibold sm-font fc-dark">
                                    There are no slots available for the selected date
                                </h6>
                            </div>
                            </div>

                        </div>



                    </div>




                </div>

                <div *ngIf="isShow"
                    class="col-lg-7 col-xl-7 col-md-8 col-sm-12 d-inline sm-font bc-pale-grey-three pl-30">
                    <h5 class=" mt-15 font-openSans-semibold fs-14 fc-dark-grey-blue pt-30">Enter Details</h5>
                    <form [formGroup]="detailsForm" (ngSubmit)="next()" class="form fc-slate-grey mt-15">
                        <div class="form-group">
                            <label class="required xs-font fc-slate-grey font-openSans">First Name</label>
                            <div class="">
                                <input type="text"
                                    class="form-control btn-block mb-0 br-10 fs-14 font-openSans fc-dark h-45"
                                    placeholder="Enter your name" formControlName="name">
                                <div class="invalid">
                                    <div *ngIf="submitted && f.name.errors?.required" class="pt-10">First Name is
                                        required</div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="required xs-font fc-slate-grey font-openSans"> E-mail</label>
                            <div class="">
                                <input type="email"
                                    class="form-control btn-block mb-0 br-10 fs-14 font-openSans fc-dark h-45"
                                    placeholder="Enter your email" formControlName="email">
                                <div class="invalid">
                                    <div *ngIf="submitted && f.email.errors?.required" class="pt-10">Email is required
                                    </div>
                                    <div *ngIf="submitted && f.email.errors?.email" class="pt-10">Invalid Email ID
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <button class="btn border-cloudy-blue br-10 next-btn fc-cloudy-blue" type="button">Add
                            Guests</button> -->

                        <div class="form-group mt-15">
                            <label class="xs-font font-openSans-regular fc-slate-grey">
                                Notes <span class="xs-font font-openSans-regular fc-slate-grey">(Please share anything
                                    that will help prepare for our meeting)</span>
                            </label>
                            <div class="">
                                <textarea type="text" class="form-control btn-block mb-0 br-10"
                                    formControlName="message" rows="5"></textarea>

                            </div>
                        </div>
                        <div class="mt-15 pull-right mb-10">
                            <button class="btn bc-soft-blue fw-600 br-10 button-width h-45 fc-white"
                                type="submit">Schedule
                                Event</button>
                        </div>
                    </form>
                </div>
            </div>
            <!-- </p-card> -->
        </div>

        <br />
    </div>
</div>

<!-- <div #container class="calendly-inline-widget" style="min-width:320px;height:580px;" data-auto-load="false"></div>
<div class="calendly-inline-widget" style="min-width:320px;height:580px;" data-auto-load="false">
    <script>
        Calendly.initInlineWidget({
            url: 'https://calendly.com/192.168.43.251:4200/patient/appointment'
        });
    </script>
</div> -->