import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvironmentService } from '../services/environment.service';
import * as moment from 'moment';
import * as momentTz from 'moment-timezone';

@Injectable({
  providedIn: 'root',
})
export class ThirdPartyService {
  model = {
    allergies: '',
    medication: '',
    pre_existing_conditions: '',
    specialty: '',
    patient_questionaries: '',
    consultationType: 'first_time',
    patient_email: '',
    patient_fname: '',
    patient_lname: '',
    patient_mobile: '',
    doctor_email: '',
    doctor_fname: '',
    doctor_lname: '',
    doctor_mobile: '',
    token: '',
    doctor_id: '',
    patient_id: '',
  };
  clientToken: any;
  code: any;
  constructor(private env: EnvironmentService, private http: HttpClient) {}

  createThirdPartyAppointment(data) {
    const { baseUrl, thirdPartyAppointment } = this.env.getSettings().api;
    const url = baseUrl + thirdPartyAppointment + '/create';
    return this.http.post(url, data, {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/json',
        // 'token': localStorage.getItem('token')
      }),
    });
  }

  finishAppointment(id, rimidi_id, data) {
    const { baseUrl, thirdPartyAppointment } = this.env.getSettings().api;
    const url =
      baseUrl + thirdPartyAppointment + '/finish/' + id + '/' + rimidi_id;
    return this.http.patch(url, data, {
      headers: new HttpHeaders({
        // 'Content-Type': 'application/json',
        // 'token': localStorage.getItem('token')
      }),
    });
  }

  // clientAuthentication(data){
  //   const { baseUrl} = this.env.getSettings().api
  //   const url = baseUrl +'/' + 'api/clients/auth';
  //   return this.http.post(url, data, {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'token': localStorage.getItem('token')
  //     })
  //   });
  // }

  convertToUnixTimeStamp(date: any, time: string) {
    const dateVal = moment(date).format('MM/DD/YYYY');
    const dateString = `${dateVal} ${time}:00`;
    const unixDateTime = new Date(dateString).valueOf();
    return unixDateTime;
  }

  convertToStandardTime(timestamp) {
    const localDate = new Date(timestamp).toLocaleString();
    return localDate;
  }

  getTimeZone(): string {
    return momentTz.tz.guess();
  }

  getTimeZoneName(): string {
    const d = new Date();
    const n = String(String(d).split('(')[1]).split(')')[0];
    return n;
  }

  getUserDetails(code, pageFrom) {
    const { baseUrl } = this.env.getSettings().api;
    const url =
      baseUrl +
      '/api/rimidi/getPatientAndDoctorInfo' +
      '?code=' +
      code +
      '&page=' +
      pageFrom;
    return this.http.get(url, {
      headers: new HttpHeaders({
        // 'token': localStorage.getItem('token')
      }),
    });
  }

  getListOfAppointments(doctor_id, from_date, to_date) {
    // query params
    const { baseUrl, rimidiAppointments } = this.env.getSettings().api;
    const url =
      baseUrl +
      rimidiAppointments +
      '/' +
      doctor_id +
      '?from_date=' +
      from_date +
      '&to_date=' +
      to_date;
    return this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'token': localStorage.getItem('token')
      }),
    });
  }
}
