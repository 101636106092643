<app-loader [loading]="loading"></app-loader>
<p-toast></p-toast>

<div class="bc-white br-10 pb-30">
    <!-- <div class="list-box">
        <div class="p-30 font-openSans-semibold">List Of Clients</div>
        <p-listbox [options]="clients" [(ngModel)]="selectedClient" optionLabel="name"></p-listbox>
    </div> -->
    <div class="p-30">
        <div>Add new Client</div>
        <input class="mt-30" type="text" pInputText [(ngModel)]="client"/>
    </div>
    <div class="mt-30 ml-30">
        <button type="btn" class="mr-20 h-45 input-btn btn fw-600  bc-soft-blue fs-14 font-openSans-semibold fc-white  br-10" (click)="getSelectedClient('s')">Create</button>
        <button type="btn" class="h-45 input-btn btn br-10 bc-white border-cloudy-blue fs-14 mr-10 font-openSans-semibold fc-cloudy-blue"  (click)="getSelectedClient('c')">Cancel</button>
    </div>
    </div>