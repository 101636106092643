import { Component, Input, OnInit } from '@angular/core';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { SharedService } from '../shared.service';
import { MessageService } from 'primeng/api';


@Component({
  selector: 'app-edit-availability',
  templateUrl: './edit-availability.component.html',
  styleUrls: ['./edit-availability.component.sass'],
  providers: [MessageService]
})
export class EditAvailabilityComponent implements OnInit {
  @Input() doctorList = [];
  editModel: NgbDate;
  availablityList = [];
  isEdit = false;
  edit_availablity_id: any;
  role: string;
  model: any;
  meridian = true;
  edit_type = 'single';
  multiple_startTime = { hour: 12, minute: 0 };
  multiple_endTime = { hour: 13, minute: 0 }
  hoveredDate: NgbDate | null = null;
  loading = false;
  selectedDoctor: any;
  fromDate: NgbDate;
  toDate: NgbDate | null = null;
  arr: any = [];
  userId: any;
  startdate: number;
  enddate: number;

  constructor(
    private calendar: NgbCalendar, private sharedService: SharedService,
    private messageService: MessageService

  ) {
    this.role = localStorage.getItem('role');
    if (this.role == 'doctor') {
      this.fromDate = calendar.getToday();
      this.toDate = calendar.getNext(calendar.getToday(), 'd', 1);
      this.startdate = moment(this.fromDate.month + '/' + this.fromDate.day + '/' + this.fromDate.year + ' ' + '00:00:00').valueOf();
      this.enddate = moment(this.toDate.month + '/' + this.toDate.day + '/' + this.toDate.year + ' ' + '23:59:00').valueOf();
    }
  }

  ngOnInit(): void {
    if (this.role == 'doctor') {
      this.userId = localStorage.getItem('user_id');
      this.getAvaiablityList(this.startdate, this.enddate)
    }
    // this.getAvaiablityList(this.startdate, this.enddate) 
  }


  selectToday() {
    this.editModel = this.calendar.getToday();
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  onDateSelection(date: NgbDate) {
    if (this.role == 'admin' && !this.selectedDoctor) {
      this.messageService.clear();
      this.messageService.add({ severity: 'warn', summary: 'Error', detail: 'Select the clinician' });
      return;
    }
    if (this.role == 'admin') {
      this.userId = this.selectedDoctor;
    }
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    // const userId = localStorage.getItem('user_id');
    if (!this.toDate || !this.fromDate) {
      return;
      this.toDate = this.fromDate
    }
    this.startdate = moment(this.fromDate.month + '/' + this.fromDate.day + '/' + this.fromDate.year + ' ' + '00:00:00').valueOf();
    this.enddate = moment(this.toDate.month + '/' + this.toDate.day + '/' + this.toDate.year + ' ' + '23:59:00').valueOf();

    this.getAvaiablityList(this.startdate, this.enddate)
  }

  // getDoctorID(value) {
  //   this.userId = value;
  //   this.getAvaiablityList(this.startdate, this.enddate);
  // }


  getAvaiablityList(startdate, enddate) {
    this.loading = true;
    this.sharedService.getAvailability(this.userId, startdate, enddate).subscribe((res) => {
      // console.log(res);
      this.loading = false
      this.availablityList = [];
      this.availablityList = res['data'];
      this.availablityList.forEach((data) => {
        const start = moment(data.start_time).toDate();
        data['edit_start'] = { hour: start.getHours(), minute: start.getMinutes() };
        const end = moment(data.end_time).toDate();
        data['edit_end'] = { hour: end.getHours(), minute: end.getMinutes() };;
      });
    });
    // console.log(this.availablityList[2]);
  }

  onEditDateSelection(event) {

    // const newDate = new Date(date);
    // console.log(newDate,'newDate')
  }

  //delete all slot for given date
  discardAll() {
    if (this.role == 'admin' && !this.selectedDoctor) {
      this.messageService.clear();
      this.messageService.add({ severity: 'warn', summary: 'Error', detail: 'Select the clinician' });
      return;
    }
    if (this.role == 'admin') {
      this.userId = this.selectedDoctor;
    }
    let arr = [];
    this.loading = true;
    this.availablityList.forEach((slot) => {
      arr.push(slot.availability_id);
    });
    const payload = {
      data: [...arr]
    }
    this.sharedService.deleteSlots(this.userId, payload).subscribe((res) => {
      this.loading = false;
      this.availablityList = [];
      this.messageService.clear();
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Time slot deleted' });
    }, (err) => {
      this.loading = false;
      this.messageService.clear();
      this.messageService.add({ severity: 'warn', summary: 'Error', detail: err.error.message });
    });
  }

  //delete single slot
  deleteSlot(id) {
    if (this.role == 'admin' && !this.selectedDoctor) {
      this.messageService.clear();
      this.messageService.add({ severity: 'warn', summary: 'Error', detail: 'Select the clinician' });
      return;
    }
    if (this.role == 'admin') {
      this.userId = this.selectedDoctor;
    }
    this.loading = true;
    const payload = {
      data: [id]
    }
    this.sharedService.deleteSlots(this.userId, payload).subscribe((res) => {
      this.loading = false;
      this.availablityList = this.availablityList.filter(ele => ele.availability_id != id);
      this.messageService.clear();
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Time slots deleted' });
    }, (err) => {
      this.loading = false;
      this.messageService.clear();
      this.messageService.add({ severity: 'warn', summary: 'Error', detail: err.error.message });

    })
  }

  editSlot(slot) {
    this.isEdit = true;
    this.edit_availablity_id = slot.availability_id;
  }

  //update the single date slot
  uplateSingleSlot(slot) {
    if (this.role == 'admin' && !this.selectedDoctor) {
      this.messageService.clear();
      this.messageService.add({ severity: 'warn', summary: 'Error', detail: 'Select the clinician' });
      return;
    }
    if (this.role == 'admin') {
      this.userId = this.selectedDoctor;
    }
    const date = moment(slot.date).format('MM/DD/YYYY');
    const obj = {
      available_slots: [{
        start_time: moment(date + ' ' + `${slot.edit_start.hour}:${slot.edit_start.minute}`).valueOf(),
        end_time: moment(date + ' ' + `${slot.edit_end.hour}:${slot.edit_end.minute}`).valueOf(),
        availability_id: slot.availability_id,
        doctor_id: this.userId,
        call_duration: slot.call_duration,
        buffer_time: slot.buffer_time,
        date: moment(date + ' ' + `${slot.edit_start.hour}:${slot.edit_start.minute}`).valueOf()
      }]
    }
    this.sharedService.updateSlot(this.userId, obj).subscribe((data) => {
      this.messageService.clear();
      this.isEdit = false;
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Time slot updated' });
      this.getAvaiablityList(this.startdate, this.enddate);
    }, (err) => {
      this.messageService.clear();
      this.messageService.add({ severity: 'warn', summary: 'Error', detail: err.error.message });

    })
  }

  //edit function 
  updateMultipleSlots() {
    // this.minimumDate = new Date(min); //min date to set when doctor available
    // this.maxDate = new Date(max); //max date to set still when doctor available
    if (this.arr.length == 0) {
      this.messageService.clear();
      this.messageService.add({ severity: 'warn', summary: 'Error', detail: 'Add Time slot' });
      return;
    }
    let startDate = moment(this.startdate);
    let endDate = moment(this.enddate);
    let date = [];
    //get all the date between date range selected
    for (var m = moment(startDate); m.isBefore(endDate); m.add(1, 'days')) {
      date.push(m.format('L'));
    }
    let dataArr = []
    this.arr.forEach(time => {
      // console.log(time)
      const [hours, min] = time['start'].split(':');
      const [end_hours, end_min] = time.end.split(':');

      this.availablityList.forEach((data) => {
        const newDate = new Date(data.date)
        const date = moment(newDate).format('MM/DD/YYYY');

        const obj = {
          start_time: moment(date + ' ' + `${hours}:${min}`).valueOf(),
          end_time: moment(date + ' ' + `${end_hours}:${end_min}`).valueOf(),
          availability_id: data.availability_id,
          doctor_id: this.userId,
          call_duration: data.call_duration,
          buffer_time: data.buffer_time,
          date: moment(date + ' ' + `${hours}:${min}`).valueOf()
        }
        dataArr.push(obj)
      })
    });
    const obj = {
      available_slots: [...dataArr]
    }
    this.loading = true;

    if (this.role == 'admin') {
      this.userId = this.selectedDoctor;
    }
    this.sharedService.updateSlot(this.userId, obj).subscribe((data) => {
      this.isEdit = false;
      this.arr = [];
      this.loading = false;
      this.messageService.clear();
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Time slot updated' });
      this.getAvaiablityList(this.startdate, this.enddate);
    }, (err) => {
      this.loading = false;
      this.messageService.clear();
      this.messageService.add({ severity: 'warn', summary: 'Error', detail: err.error.message });
    })
    // cons
    // console.log(date, dataArr)
  }

  dateConversion(date) {
    return moment(date).format('ll');
  }

  timeConversion(time) {
    return moment(time).format('LT'); //12:27 pm
  }

  addTime(start, end) {
    const obj = {
      start: start.hour + ':' + start.minute,
      end: end.hour + ':' + end.minute
    }
    //select only one time slot
    if (this.arr.length == 1) {
      this.messageService.clear();
      this.messageService.add({ severity: 'warn', summary: 'Warn', detail: 'You can select single time slot' });
      return;
    }
    if (this.validateDate(obj)) {
      const presentVal = this.arr.filter(ele => ele.start == obj.start && ele.end && obj.end);
      if (!presentVal.length) {
        this.arr.push(obj)
      }
    };
  }

  clear() {
    this.arr = [];
  }

  validateDate(obj) {
    var timefrom = new Date();
    let temp = obj.start.split(":");
    timefrom.setHours((parseInt(temp[0]) - 1 + 24) % 24);
    timefrom.setMinutes(parseInt(temp[1]));

    var timeto = new Date();
    let temp1 = obj.end.split(":");
    timeto.setHours((parseInt(temp1[0]) - 1 + 24) % 24);
    timeto.setMinutes(parseInt(temp1[1]));
    if (timeto < timefrom) {
      alert('Start time should be smaller than end time!');
      return false
    }
    return true
  }
}
