import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HornComponentComponent } from './horn-component/horn-component.component';
import { HeaderComponent } from './header/header.component';
import { RouterModule } from '@angular/router';
import { DialogModule } from 'primeng/dialog';
import { LayoutModule } from '../layout/layout.module';
import { FormsModule,FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { TableModule } from 'primeng/table';
import { AccordionModule } from 'primeng/accordion';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {ToastModule} from 'primeng/toast';
import {MenuModule} from 'primeng/menu';
import { AvatarModule } from 'ngx-avatar';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';

import { CalendarModule } from 'primeng/calendar';
import {ListboxModule} from 'primeng/listbox';
import { ReactiveFormsModule } from '@angular/forms';
import { CardModule } from 'primeng/card';
import { TableComponent } from './table/table.component';
import { CommentsComponent } from './comments/comments.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { CalendlyComponent } from './calendly/calendly.component';
import { AppointmentListComponent } from './appointment-list/appointment-list.component';
import { AppointmentDetailsComponent } from './appointment-details/appointment-details.component';
import { NgbModule,NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { QuestionaireComponent } from './questionaire/questionaire.component';
import { AppointmentSlotComponent } from './appointment-slot/appointment-slot.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { LoaderComponent } from './loader/loader.component';
import { DropdownModule } from 'primeng/dropdown';
import { SearchPipe } from './search.pipe';
import { AvailabilityComponent } from './availability/availability.component';
import {NgbDate, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import { ClientsComponent } from './clients/clients.component';
import {InputTextModule} from 'primeng/inputtext';
import { EditAvailabilityComponent } from './edit-availability/edit-availability.component';
import { PanelModule } from 'primeng/panel';
import { TabViewModule } from 'primeng/tabview';

import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import interactionPlugin from '@fullcalendar/interaction';
import { ConfirmDailogComponent } from './confirm-dailog/confirm-dailog.component';
import { ConfirmCancellationComponent } from './confirm-cancellation/confirm-cancellation.component'; // a plugin

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin
]);
@NgModule({
  declarations: [HornComponentComponent,
    HeaderComponent,
    SideNavComponent,
    CalendlyComponent,
    AppointmentListComponent,
    AppointmentDetailsComponent,
    TableComponent,
    CommentsComponent,
    QuestionaireComponent,
    AppointmentSlotComponent,
    ConfirmationComponent,
    LoaderComponent,
    SearchPipe,
    AvailabilityComponent,
    ClientsComponent,
    EditAvailabilityComponent,
    ConfirmDailogComponent,
    ConfirmCancellationComponent
  ],
  imports: [
    MenuModule,
    MatIconModule,
    MatMenuModule,
    CommonModule,
    RouterModule,
    DialogModule,
    FormsModule,
    TableModule,
    AccordionModule,
    CardModule,
    CalendarModule,
    ReactiveFormsModule,
    DropdownModule,
    AutoCompleteModule,
    ToastModule,
    AvatarModule,
    NgxDaterangepickerMd.forRoot(),
    NgbModule,
    ListboxModule,
    InputTextModule,
    PanelModule,
    TabViewModule,
    FullCalendarModule
  ],
  exports: [
    HornComponentComponent,
    HeaderComponent,
    SideNavComponent,
    CalendlyComponent,
    TableComponent,
    AppointmentListComponent,
    AppointmentDetailsComponent,
    QuestionaireComponent,
    AppointmentSlotComponent,
    ConfirmationComponent,
    LoaderComponent,
    SearchPipe,
    AvailabilityComponent,
    ClientsComponent,
    EditAvailabilityComponent,
    DialogModule,
    ConfirmCancellationComponent
  ],
  providers: [
    MatIconRegistry
  ],
  // bo2otstrap: [AvailabilityComponent]
})
export class SharedModule { }
