<p-toast></p-toast>

<div class="col-12 bc-white br-10 mb-20 pb-10">
    <div class="br-10">
        <div class="row ptd-20 plr-0 col-12">

            <div class="col-lg-9 col-xl-9 col-md-7 col-sm-8 col-sm-8 pr-0">
                <span class="fc-dark-grey-blue fs-32 font-openSans-semibold ">Appointment
                    Details</span>
                <p class="p-0 col-12 fs-14 fc-twilight">Summary of the patient visit</p>
            </div>
            <div class="col-lg-3 col-xl-3 col-md-4 col-sm-12 col-xs-12 ">

                <button *ngIf="appointmentDetails?.appointment_status == 'created'" type="button"
                    class="mb-10 input-btn btn fw-600 button-width bc-soft-blue fs-14 h-45 lh-025 font-openSans-semibold fc-white br-10"
                    (click)="join(appointmentDetails.appointment_id)">Join</button>
            </div>
        </div>
        <div class="cover-grid bc-pale-grey-three col-12" *ngIf="appointmentDetails">
            <div class="card-details sm-font p-0 col-12">
                <div class="d-flex mt-20 flexWrap fc-dark mb-20">
                    <!-- <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-xs-12 profile d-flex flexWrap mr-10"> -->
                    <div class="col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12 sm-font fw-600 p-details">
                        <label class="fc-dark-grey-blue fs-18 font-openSans-semibold">Patient Details</label>
                        <div class="fc-dark font-openSans-semibold sm-font">Name:
                            {{patient_name | titlecase}}
                            </div>
                        <div class="fc-dark font-openSans-semibold sm-font" *ngIf="doctor_specialty != 'Behavioral'">MRN:
                            {{patient_mrn | titlecase}}</div>
                        <div class="fc-dark font-openSans-semibold sm-font" *ngIf="doctor_specialty == 'Behavioral'">Account:
                            {{patient_mrn | titlecase}}</div>
                    </div>
                    <!-- </div> -->
                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 sm-font fw-600 v-details">
                        <label class="fc-dark-grey-blue fs-18 font-openSans-semibold">Visit Details</label>
                        <div class="fc-dark font-openSans-semibold sm-font">Visit Type:
                            {{visit_type}}</div>
                         <div class="fc-dark font-openSans-semibold sm-font">Specialty:
                            {{doctor_specialty}}</div>
                        <div class="fc-dark font-openSans-semibold sm-font">Date and Time: {{date}} {{start_time | lowercase}} -
                            {{end_time | lowercase}}</div>
                        <div class="fc-dark font-openSans-semibold sm-font">Doctor Name: {{appointmentDetails?.doctor?.first_name | titlecase}} {{appointmentDetails?.doctor?.last_name | titlecase}}</div>
                        <div class="fc-dark font-openSans-semibold sm-font" *ngIf="doctor_specialty == 'Behavioral'">Referring:
                            {{referring_doctor}}</div>
                        <div class="fc-dark font-openSans-semibold sm-font">Status: {{appointmentDetails?.appointment_status | titlecase}}</div>
                    </div>
                </div>
                <div class="pl-10">
                    <p-accordion [multiple]="true" styleClass="appointmentDetails">
                        <p-accordionTab header="Chief Medical Complaint">
                            <div class="row col-12 d-flex flexWrap sm-font">
                                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12 mb-10">
                                    <div class=""
                                        class="text-overflow cover-grid bc-pale-grey p10  h-97 overflow-auto">
                                        {{appointmentDetails?.patient_complaint}}
                                    </div>
                                </div>

                            </div>
                        </p-accordionTab>

                        <p-accordionTab header="Other Medical Details">
                            <div class="row col-12 mt-10 p-0 ml-03">
                                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12 mb-10">
                                    <label
                                        class="fc-dark-grey-blue fs-14 fw-600 font-openSans-regular">Allergies</label>
                                    <div class=""
                                        class="text-overflow cover-grid bc-pale-grey p10  h-97 overflow-auto">
                                        {{appointmentDetails?.patient_allergies}}
                                    </div>
                                </div>

                                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12 mb-10">
                                    <label
                                        class="fc-dark-grey-blue fs-14 fw-600 font-openSans-regular">Medications</label>
                                    <div class=""
                                        class="text-overflow cover-grid bc-pale-grey p10  h-97 overflow-auto">
                                        {{appointmentDetails?.patient_medication}}
                                    </div>
                                </div>

                                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12 mb-10">
                                    <label class="fc-dark-grey-blue fs-14 fw-600 font-openSans-regular">Pre-existing
                                        conditions</label>
                                    <div class="d-grid"
                                        class="text-overflow cover-grid bc-pale-grey p10 h-97 overflow-auto">
                                        {{appointmentDetails?.patient_preexisting_conditions}}
                                    </div>
                                </div>
                            </div>
                        </p-accordionTab>
                    </p-accordion>
                    <div class="mt-15 mb-30"></div>
                    <div class="mt-15 mb-30 col-12 p-0" *ngIf="">
                        <label class="fs-18 font-openSans-semibold fc-dark-grey-blue ml-30 prev-visits">Previous Visits</label>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex flexWrap ml-15">
                            <div
                                class="bc-pale-grey col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 fw-600 cover-grid mr-10 mt-10">
                                <div class="ptd-10  fc-dark fs-11 font-openSans-semibold pl-05">24 April 20 | 03:30 -
                                    04:00 pm
                                </div>
                                <p class="ptd-10 p5">
                                    <span class="fs-14 pull-left clear font-openSans-semibold fc-dark"
                                        style="flex:1">FEVER</span>
                                    <span class="pull-right fs-11 clear font-openSans-semibold fc-dark">Details >
                                        <!-- <i
                                            class="fa fa-angle-right" aria-hidden="true"></i> -->
                                    </span>
                                </p>
                            </div>
                            <div
                                class="bc-pale-grey col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 fw-600 cover-grid mr-10 mt-10">
                                <div class="ptd-10  fc-dark fs-11 font-openSans-semibold pl-05">24 April 20 | 03:30 -
                                    04:00 pm
                                </div>
                                <div class="ptd-10 p5 d-flex">
                                    <div class="fg-1 fs-14 font-openSans-semibold fc-dark" style="flex:1">STOMACH FLU
                                    </div>
                                    <div class="fs-11 font-openSans-semibold fc-dark">Details >
                                        <!-- <i
                                            class="fa fa-angle-right" aria-hidden="true"></i> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="fc-dark font-openSans-semibold back-btn cp" (click)="navigateToList()">
                    <i class="fa fa-arrow-circle-o-left" aria-hidden="true"></i>
                    Back
                </div>
            </div>
        </div>

       
    </div>
</div>