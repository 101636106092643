import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import * as moment from 'moment';
import { DoctorService } from 'src/app/features/doctor/doctor.service';
import * as cloneDeep from 'lodash/cloneDeep';
import { SearchPipe } from '../search.pipe';
import { MessageService } from 'primeng/api';
import { SharedService } from 'src/app/shared/shared.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDailogComponent } from '../confirm-dailog/confirm-dailog.component';
import { AuthService } from 'src/app/security/auth.service';
@Component({
  selector: 'app-appointment-list',
  templateUrl: './appointment-list.component.html',
  styleUrls: ['./appointment-list.component.sass'],
  providers: [MessageService, SearchPipe, NgbModal],
})
export class AppointmentListComponent implements OnInit, OnChanges {
  searchField: string = 'patient_name';
  user_id: string;
  role: string;
  dateRange: any;
  isRimidyUser = false;

  constructor(
    public ds: DoctorService,
    public SearchPipe: SearchPipe,
    private messageService: MessageService,
    public sharedService: SharedService,
    private auth: AuthService,
    private router: Router,
    private modalService: NgbModal,
    private activateRoute: ActivatedRoute
  ) {
    this.alwaysShowCalendars = true;
  }
  @Input() appointmentList = [];
  @Input() type = '';
  @Input() header = '';
  @Output() dateEvent: EventEmitter<any> = new EventEmitter();
  @Input() doctorList = [{ label: 'Select Clinician', value: null }];

  searchVal = '';
  offset = 0;
  limit = 5;
  enableLeft = false;
  enableRight = false;
  appData: any = [];
  // doctorList = [{ label: 'Select Doctor', value: null }];
  selected: any;
  // selectDoctor = '';
  alwaysShowCalendars: boolean;
  placeholder = '';
  loading: boolean = false;
  ranges: any = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [
      moment().subtract(1, 'month').startOf('month'),
      moment().subtract(1, 'month').endOf('month'),
    ],
  };
  // invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];
  invalidDates: moment.Moment[] = [];
  @Output() updateStatus = new EventEmitter();

  isInvalidDate = (m: moment.Moment) => {
    return this.invalidDates.some((d) => d.isSame(m, 'day'));
  };

  getUrl() {
    return `/${this.type}/appointment`;
  }

  ngOnInit(): void {
    // this.getDoctorList();
    // if(!this.sharedService.CalendarDate){
    //   this.sharedService.CalendarDate = new Date();
    // }
    this.activateRoute.queryParams.subscribe((params) => {
      if (!params.availability) {
        this.sharedService.CalendarDate = new Date();
        this.selected = new Date();
        this.sharedService.selectDoctor = '';
      }
    });
    this.isRimidyUser = this.auth.isRimidyUser();
    this.user_id = localStorage.getItem('user_id');
    this.role = localStorage.getItem('role');
    this.selected = {
      startDate: this.sharedService.CalendarDate,
      endDate: this.sharedService.CalendarDate,
    };
  }

  changed(value) {
    this.dateRange = value;
    if (!this.dateRange.startDate || !this.dateRange.endDate) {
      return;
    }
    let obj = {
      start_date: this.dateRange.startDate._d
        ? this.dateRange.startDate._d
        : '',
      end_date: this.dateRange.endDate._d ? this.dateRange.endDate._d : '',
      selected_doctor: this.sharedService.selectDoctor,
    };
    this.dateEvent.emit(obj);
  }

  getSelectedDoctor(doctor) {
    this.sharedService.selectDoctor = doctor;
    let date = new Date();
    const startDate = this.sharedService.convertToUnixTimeStamp(date, '00:00');
    const endDate = this.sharedService.convertToUnixTimeStamp(date, '23:59');
    let obj = {
      start_date: this.dateRange.startDate
        ? this.dateRange.startDate._d
        : startDate,
      end_date: this.dateRange.endDate ? this.dateRange.endDate._d : endDate,
      selected_doctor: this.sharedService.selectDoctor,
    };
    this.dateEvent.emit(obj);
  }

  setAppointmentId(id) {
    this.ds.appointment_id = id;
    localStorage.setItem('appointment', id);
  }

  join(appointment, id) {
    if (
      !this.sharedService.joinCall(appointment.start_time, appointment.end_time)
    ) {
      this.messageService.clear();
      this.messageService.add({
        severity: 'info',
        summary: 'Info',
        detail: 'Please join the call at the given time',
      });
      return;
    }
    const horn_id = appointment.horn_channel_id;
    const role = localStorage.getItem('role');
    localStorage.setItem('appointment', id);
    if (appointment.horn_client_id) {
      this.router.navigate(
        [
          `/client/appointment`,
          appointment.horn_client_name,
          appointment.horn_client_id,
        ],
        {
          queryParams: {
            page: 'video',
            id: horn_id,
            clientId: appointment.horn_client_id,
            user_id: this.user_id,
            appointment_id: id,
          },
        }
      );
    } else {
      this.router.navigate([`/${this.type}/appointment`], {
        queryParams: {
          page: 'video',
          id: horn_id,
          user_id: this.user_id,
          appointment_id: id,
        },
      });
    }
  }

  appointmentUrl() {
    return `/${this.type}/appointment`;
  }

  ngOnChanges(value: SimpleChanges) {
    if (this.type == 'patient') {
      this.placeholder = 'Search for Doctor';
    } else {
      this.placeholder = 'Search for Patient';
    }
    this.searchField = this.type == 'patient' ? 'doctor_name' : 'patient_name';
    if (value.appointmentList) {
      this.appointmentList = value.appointmentList.currentValue;
      this.pagination();
      //   this.appointmentList = value['appointmentList']['currentValue']
    }
  }

  // getMenuItemsForItem(item?): MenuItem[] {
  //   //selected row value
  //   const context = item;
  //   return [
  //     {
  //       label: 'Reschedule', disabled: context.appointment_status == 'completed' || context.appointment_status == 'cancelled' || context.appointment_status == 'rescheduled', command: (event: any) => {
  //         this.confirmReschedule(context);
  //       }
  //     },
  //     {
  //       label: 'Cancel', disabled: context.appointment_status == 'cancelled' || context.appointment_status == 'completed' || context.appointment_status == 'rescheduled', command: (event: any) => {
  //         this.confirmCancel(context);
  //       }
  //     }
  //   ]
  // }

  confirmCancel(data) {
    const modalRef = this.modalService.open(ConfirmDailogComponent, {
      centered: true,
    });
    modalRef.componentInstance.title = 'Cancel Appointment';
    modalRef.componentInstance.message =
      'Are you sure you want to cancel the following appointment?';
    modalRef.componentInstance.btnOkText = 'Confirm';
    modalRef.componentInstance.btnCancelText = 'Back';
    modalRef.componentInstance.data = data;
    modalRef.result.then((result) => {
      if (result == true) {
        const payload = {
          status: 'cancelled',
        };
        this.loading = true;
        this.sharedService
          .cancelAppointment(data.appointment_id, payload)
          .subscribe(
            (res) => {
              if (res['success'] == true) {
                this.loading = false;
                data.appointment_status = 'cancelled';
                // this.updateStatus.emit(data);
                this.messageService.clear();
                this.messageService.add({
                  severity: 'success',
                  summary: 'Success',
                  detail: res['message'],
                });
                return;
              }
            },
            (err) => {
              this.loading = false;
              this.messageService.clear();
              this.messageService.add({
                severity: 'warn',
                summary: 'Error',
                detail: err.error.error.message,
              });
              return;
            }
          );
      }
    });
  }

  confirmReschedule(data) {
    const modalRef = this.modalService.open(ConfirmDailogComponent, {
      centered: true,
    });
    modalRef.componentInstance.title = 'Reschedule Appointment';
    modalRef.componentInstance.message =
      'Are you sure you want to reschedule the following appointment?';
    modalRef.componentInstance.btnOkText = 'Confirm';
    modalRef.componentInstance.btnCancelText = 'Back';
    modalRef.componentInstance.data = data;
    modalRef.result.then((result) => {
      if (result == true) {
        const role = localStorage.getItem('role');
        this.router.navigate([`/${role}/appointment`], {
          queryParams: {
            page: 'appointment-slots',
            reschedule: data.appointment_id,
            doctorID: data.doctor_id,
          },
        });
      }
    });
  }

  pagination(flag?) {
    if (flag == 'p') {
      this.offset--;
    } else if (flag == 'n') {
      this.offset++;
    }
    if (this.offset !== 0 || this.offset > 0) {
      this.enableLeft = true;
    } else if (this.offset <= 0) {
      this.enableLeft = false;
    }
    if (this.offset < Math.ceil(this.appointmentList.length / this.limit - 1)) {
      this.enableRight = true;
    } else if (
      this.offset >= Math.ceil(this.appointmentList.length / this.limit - 1)
    ) {
      this.enableRight = false;
    }
    let index = this.offset * this.limit;
    let array1 = cloneDeep(this.appointmentList);
    this.appData = array1.slice(index, index + this.limit);
  }
}
