import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';
import { AuthService } from '../app/security/auth.service';
import { BnNgIdleService } from 'bn-ng-idle';
import { SharedService } from './shared/shared.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
})
export class AppComponent implements OnInit {
  title = 'televiewmdwebapp';
  constructor(
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private router: Router,
    private AuthService: AuthService,
    private bnIdle: BnNgIdleService,
    private sharedService: SharedService
  ) {
    translateService.addLangs(['en', 'sp']);
    translateService.setDefaultLang('sp');
  }

  switchLanguage(language: string) {
    this.translateService.use(language);
  }

  ngOnInit() {
    this.bnIdle.startWatching(1800).subscribe((isTimedOut: boolean) => {
      const isRimidyUser = this.AuthService.isRimidyUser();
      if (isTimedOut && !this.sharedService.timerNotRequired && !isRimidyUser) {
        this.router.navigateByUrl('/login');
        this.AuthService.signOut();
        this.sharedService.modalRef.close();
      }
    });
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const rt = this.getChild(this.activatedRoute);
        rt.data.subscribe((data) => {
          if (
            this.AuthService.isAuthenticated() &&
            (data.title == 'TeleviewMD | Registration Form' ||
              data.title == 'TeleviewMD | Registration Details')
          ) {
            const role = localStorage.getItem('role').toUpperCase();
            this.titleService.setTitle(`TeleviewMD | ${role} | Profile`);
          } else {
            this.titleService.setTitle(data.title);
          }
        });
      });
  }
  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }
}
