import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-confirm-cancellation',
  templateUrl: './confirm-cancellation.component.html',
  styleUrls: ['./confirm-cancellation.component.sass']
})
export class ConfirmCancellationComponent implements OnInit {

  constructor(private router: Router) { }
  @Input() data;
  @Input() type = '';
  ngOnInit(): void {
  }

  reset() {
    const role = localStorage.getItem('role');
    this.router.navigate([`/${role}/dashboard`])
  }
}
