import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DoctorService } from 'src/app/features/doctor/doctor.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDailogComponent } from '../confirm-dailog/confirm-dailog.component';
import { SharedService } from '../shared.service';
import { MessageService } from 'primeng/api';
import { AuthService } from 'src/app/security/auth.service';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.sass'],
  providers: [NgbModal],
})
export class TableComponent implements OnInit, OnChanges {
  cols: any[];
  @Input() tableHeaders: any = [];
  @Input() tableData: any = [];
  @Input() scrollable = false;
  @Input() selectedDoctor: any;
  @Output() modalEvent: EventEmitter<any> = new EventEmitter();

  offset = 0; // page offset
  maxOffset: number;
  limit = 2; //max number of rows
  minCount = 1;
  maxCount = 2;
  enableLeft = false; // enable/disable prev button
  enableRight = false; // enable/disable next button
  totalobs = 0; //length of the table data
  displayData = [];
  items: MenuItem[];
  loading: boolean = false;
  @Output() cancelInformation = new EventEmitter();
  doctorID: any;
  isRimidyUser = false;
  constructor(
    public doctorService: DoctorService,
    private router: Router,
    private messageService: MessageService,
    private activateRoute: ActivatedRoute,
    public sharedService: SharedService,
    private modalService: NgbModal,
    private auth: AuthService
  ) {}

  ngOnInit(): void {
    const token = localStorage.getItem('token');
    const role = localStorage.getItem('role');
    let id = '';
    this.isRimidyUser = this.auth.isRimidyUser();
    if (role == 'patient') {
      if (token) {
        this.activateRoute.queryParams.subscribe((params) => {
          id = params.appointment_id;
          if (params.isCancel) {
            this.getAppointmentDetails(params.appointment_id, 'c');
          } else if (params.isReschedule) {
            this.getAppointmentDetails(params.appointment_id, 'r');
          }
        });
      }
    }
  }

  // getMenuItemsForItem(item?): MenuItem[] {
  //   //selected row value
  //   const context = item;
  //   return [
  //     {
  //       label: 'Join', disabled: context.status == 'cancelled' || context.status == 'completed' || context.status == 'rescheduled',
  //       command: (event: any) => { this.joinAppointment(context); }
  //     },
  //     {
  //       label: 'View', command: (event: any) => {
  //         this.getUserID(context);
  //       }
  //     },
  //     {
  //       label: 'Reschedule', disabled: context.status == 'completed'  || context.status == 'rescheduled' ||  context.status == 'cancelled', command: (event: any) => {
  //         // this.getDoctorID(context);
  //         this.confirmReschedule(context);
  //       }
  //     },
  //     {
  //       label: 'Cancel', disabled: context.status == 'cancelled' || context.status == 'completed' || context.status == 'rescheduled', command: (event: any) => {
  //         this.confirmCancel(context);
  //       }
  //     }
  //   ]
  // }

  getAppointmentDetails(id, flag) {
    this.sharedService.getAppointmentDetails(id).subscribe((res) => {
      console.log(res, 'res for appointment details');
      let data = {};
      (data['appointment_id'] = res['data']['appointment_id']),
        (data['channel_id'] = res['data']['horn_channel_id']),
        (data['date'] = this.sharedService
          .convertToStandardTime(res['data']['start_time'])
          .split(',')[0]),
        (data['date_time'] = this.sharedService.convertToStandardTime(
          res['data']['start_time']
        )),
        (data['doctor_id'] = res['data']['doctor']['user_id']),
        (data['doctor_name'] =
          'Dr.' +
          ' ' +
          res['data']['doctor']['first_name'] +
          ' ' +
          res['data']['doctor']['last_name']),
        (data['status'] = res['data']['appointment_status']),
        (data['time'] = this.sharedService
          .convertToStandardTime(res['data']['start_time'])
          .split(',')[1]),
        (data['view'] = 'View');
      // console.log(data,"data")
      if (flag == 'c' && data['status'] != 'cancelled') {
        this.confirmCancel(data);
      } else if (flag == 'r' && data['status'] != 'cancelled') {
        this.confirmReschedule(data);
      }
    }),
      (err) => {};
  }

  getUserID(data) {
    // console.log(data,"data");
    const role = localStorage.getItem('role');
    localStorage.setItem('appointment', data.appointment_id);
    this.doctorService.appointment_id = data.appointment_id;
    if (role == 'doctor') {
      this.router.navigate([`/doctor/appointment`], {
        queryParams: { page: 'details' },
      });
    } else if (role == 'patient') {
      this.router.navigate([`/patient/appointment`], {
        queryParams: { page: 'appointment-details' },
      });
    } else if (role == 'admin') {
      this.router.navigate([`/admin/appointment`], {
        queryParams: { page: 'details' },
      });
    }
  }

  confirmCancel(data) {
    this.sharedService.modalRef = this.modalService.open(
      ConfirmDailogComponent,
      { centered: true }
    );
    this.sharedService.modalRef.componentInstance.title = 'Cancel Appointment';
    this.sharedService.modalRef.componentInstance.message =
      'Are you sure you want to cancel the following appointment?';
    this.sharedService.modalRef.componentInstance.btnOkText = 'Confirm';
    this.sharedService.modalRef.componentInstance.btnCancelText = 'Back';
    this.sharedService.modalRef.componentInstance.data = data;
    this.sharedService.modalRef.result.then((result) => {
      if (result == true) {
        const payload = {
          status: 'cancelled',
        };
        this.loading = true;
        this.sharedService
          .cancelAppointment(data.appointment_id, payload)
          .subscribe(
            (res) => {
              if (res['success'] == true) {
                this.loading = false;
                this.cancelInformation.emit(data);
                const role = localStorage.getItem('role');
                this.router.navigate([`/${role}/dashboard`], {
                  queryParams: { confirmation: 'cancelled' },
                });
                this.messageService.clear();
                this.messageService.add({
                  severity: 'success',
                  summary: 'Success',
                  detail: res['message'],
                });
                return;
              }
            },
            (err) => {
              this.loading = false;
              this.messageService.clear();
              this.messageService.add({
                severity: 'warn',
                summary: 'Error',
                detail: err.error.error.message,
              });
              return;
            }
          );
      }
    });
  }

  confirmReschedule(data) {
    this.sharedService.modalRef = this.modalService.open(
      ConfirmDailogComponent,
      { centered: true }
    );
    this.sharedService.modalRef.componentInstance.title =
      'Reschedule Appointment';
    this.sharedService.modalRef.componentInstance.message =
      'Are you sure you want to reschedule the following appointment?';
    this.sharedService.modalRef.componentInstance.btnOkText = 'Confirm';
    this.sharedService.modalRef.componentInstance.btnCancelText = 'Back';
    this.sharedService.modalRef.componentInstance.data = data;
    this.sharedService.modalRef.result.then((result) => {
      if (result == true) {
        const role = localStorage.getItem('role');
        this.router.navigate([`/${role}/appointment`], {
          queryParams: {
            page: 'appointment-slots',
            reschedule: data.appointment_id,
            doctorID: data.doctor_id,
          },
        });
      }
    });
  }

  // getDoctorID(context) {
  //   console.log(context,"context");
  //   if(context.doctor_name){
  //     this.sharedService.getListOfDoctors().subscribe(
  //       res => {
  //         res['response']['data'].forEach(ele => {
  //           ele['name'] = "Dr." + " " +  ele['first_name'] + " " + ele['last_name'];
  //           if (context.doctor_name == ele['name']) {
  //             this.doctorID = ele['user_id'];
  //           }
  //         });
  //       },
  //       err => {
  //         this.messageService.clear();
  //         // this.messageService.add({ severity: 'warn', summary: 'Error', detail: err.error.message });
  //       }
  //     );
  //   }
  //   this.confirmReschedule(context);
  // }

  joinAppointment(appointment) {
    const role = localStorage.getItem('role');
    this.router.navigate([`/${role}/appointment`], {
      queryParams: {
        page: 'video',
        id: appointment.channel_id,
        appointment_id: appointment.appointment_id,
      },
    });
    localStorage.setItem('appointment', appointment.appointment_id);
  }

  patientApproval(data) {
    const role = localStorage.getItem('role');
    localStorage.setItem('patient_Id', data.user_id);
    this.router.navigate([`/${role}/patient-forms`]);
  }

  ngOnChanges(data: SimpleChanges) {
    if (data.tableData) {
      this.totalobs = this.tableData.length;
      this.calculateCount();
      this.getTableData();
    }
  }

  //pagination
  calculateCount(): void {
    this.minCount =
      this.totalobs == 0
        ? 0
        : this.offset > 0
        ? this.offset * this.limit + 1
        : 1;
    this.maxCount = (this.offset + 1) * this.limit;
    this.maxCount =
      this.maxCount < this.totalobs
        ? (this.offset + 1) * this.limit
        : this.totalobs;
  }

  getTableData(): void {
    const list = [...this.tableData];
    const pageNum = this.offset * this.limit;
    this.displayData = list.slice(pageNum, this.limit * (this.offset + 1));
  }
  previous(): void {
    this.offset--;
    if (this.offset >= 0) {
      this.enableRight = true;
      this.enableLeft = this.offset === 0 ? false : true;
      // this.loading = true;
      // this.getListRfiData(this.offset, this.limit);
      // version 1 code end
      this.getTableData();
      this.calculateCount();
    } else {
      this.offset = 0;
      this.enableLeft = false;
    }
  }

  next(): void {
    this.maxOffset = Math.ceil(this.totalobs / this.limit);
    if (this.maxOffset > 1) {
      this.enableRight = true;
    }
    this.enableRight = this.maxOffset - 1 == this.offset ? false : true;
    this.enableLeft = this.offset != 0 ? true : false;
    this.offset++;
    if (this.offset < this.maxOffset) {
      this.enableLeft = true;
      this.enableRight = this.maxOffset - 1 === this.offset ? false : true; // this.getListRfiData(this.offset, this.limit);

      // version 1 code end
      this.calculateCount();
      this.getTableData();
    } else {
      this.offset = this.maxOffset;
      this.enableRight = false;
    }
  }

  navigateToSM(value) {
    this.sharedService.homeFlag = true;
    this.sharedService.selectedDoctor = this.selectedDoctor;
    this.sharedService.newDate1 = new Date(value.epochDate);
    let doctorName;
    this.sharedService.getActiveDoctors().subscribe((data) => {
      data['response']['data'].forEach((element) => {
        if (this.sharedService.selectedDoctor == element['user_id']) {
          doctorName = element['first_name'] + ' ' + element['last_name'];
        }
      });
    });
    // this.router.navigate(['/admin/availablity'], { queryParams: { availability : 'yes' , doctorID : this.sharedService.selectedDoctor , day : value.epochDate} });

    setTimeout(() => {
      let obj = {
        doctorID: this.sharedService.selectedDoctor,
        from_date: this.sharedService.convertToUnixTimeStamp(
          this.sharedService.newDate1,
          '00:00'
        ),
        to_date: this.sharedService.convertToUnixTimeStamp(
          this.sharedService.newDate1,
          '23:59'
        ),
        doctor_name: doctorName,
      };
      this.modalEvent.emit(obj);
    }, 1000);
  }

  navigateToList(data) {
    // console.log(data);
    this.sharedService.date = data.epochDate;
    this.sharedService.CalendarDate = new Date(data.epochDate);
    // console.log( this.sharedService.CalendarDate );
    this.sharedService.selectDoctor = data.doctor;
    this.router.navigate(['/admin/appointment'], {
      queryParams: { page: 'list', availability: 'yes' },
    });
  }
}
